export default {
  "additionalQuestion": {
    "adultFemale": "女性",
    "adultMale": "男性",
    "adultOther": "その他",
    "answerQuestions": "以下の追加質問にお答えください。",
    "checkinTime": "チェックイン時間",
    "checkoutTime": "チェックアウト時間",
    "moreDetailed": "宿泊内容詳細",
    "notificationFromFacility": "施設からのお知らせ",
    "selectTime": "時間を選択",
    "view": "表示",
    "customizeYourStayExp": "滞在体験をカスタマイズする",
    "customizeYourStayExpDesc": "いくつか追加の質問に答えていただけますか?"
  },
  "base": {
    "account": "アカウント",
    "all": "すべて",
    "apply": "適用",
    "attachFile": "ファイルを添付する",
    "backTo": "{0}に戻る",
    "booking": "予約一覧",
    "browserWarning": "現在ご利用のブラウザは公式サポートが終了しております。最新のブラウザをインストールの上、再度お試しください。",
    "cancel": "キャンセル",
    "close": "閉じる",
    "confirm": "予約を確定する",
    "continue": "次へ",
    "collapse": "縮小する",
    "delete": "削除",
    "edit": "変更",
    "error": "申し訳ございません、システムエラーが発生しました。少し時間を空けてから、再度お試しください。",
    "error02": "申し訳ございません、処理中にエラーが発生しました。少し時間を空けてから、再度お試しください。",
    "errorPaymentMethod": "支払い方法が異なるため、ご予約プロセスを進めることが出来ません。複数のお部屋を予約する場合、同じお支払い方法をお選びいただくか、別々のご注文でお部屋をご予約ください。",
    "oldErrorCode": {
      "1403": "このアカウントは施設によって登録されていますが、パスワードをリセットする必要があります。サインインページに戻り、[パスワードをお忘れの方]を押してください。"
    },
    "errorCode": {
      "1903": "予約可能な期間は、本日から {reservable_period_months}ヶ月です。\n予約可能な期間を指定してください。",
      "2304": "原因は不明ですが、プラン設定がアカウントのステータスと一致しません。 ページを更新して再度ご予約ください。",
      "2401": "{currentPoint}ポイントのポイント残高です。{spendPoint}ポイントを使うことができません。",
      "3001": "タイムアウトエラーが発生しました。決済処理は行われておりません。",
      "3002": "決済処理に失敗しました。",
      "3003": "このカードはご利用できません。",
      "3004": "カード番号をご確認ください。",
      "3005": "有効期限をご確認ください。",
      "3006": "3Dセキュア認証に失敗しました。",
      "3007": "ご利用できないカードを入力されたか、カード番号に間違いがあります。",
      "3008": "3Dセキュア認証がキャンセルされました。",
      "3009": "複数回のリクエストを受けたので、3Dセキュア認証を中断しました。",
      "3011": "タイムアウトエラーが発生しました。決済処理は行われておりません。",
      "3013": "予約の更新に問題が発生しています。（他の人が更新中である可能性があるか、支払い取引が進行中の可能性があります。）したがって、現時点ではこの予約を更新できません。5分後に再試行してください。",
      "3014": "元の予約内容が変更されたため、キャンセルして新しい内容を確認してから再度変更してください。",
      "3016": "予約の更新に問題が発生しています。（他の人が更新中である可能性があるか、支払い取引が進行中の可能性があります。）したがって、現時点ではこの予約を更新できません。5分後に再試行してください。",
      "3202": "ポイント残高が取得できません。",
      "3203": "申し訳ございません、処理中にエラーが発生しました。少し時間を空けてから、再度お試しください。",
      "3204": "支払いプロセスが失敗しました。別のカードを使用するか、後で再試行してください。",
      "3205": "このカードはご利用できません。",
      "3250": "3Dセキュア認証に失敗しました。",
      "3251": "申し訳ございません、処理中にエラーが発生しました。少し時間を空けてから、再度お試しください。",
      "3252": "入力されたカードは3Dセキュアに対応していません。",
      "3502": "ポイント残高が取得できません。",
      "3504": "ポイントの返却ができませんでした。ポイント履歴を確認して下さい。",
      "3750": "こちらの日程は抽選イベントのみに適用されます。詳細はホテルサイトをご確認ください。",
      "3751": "こちらの抽選イベントは受付期間外です。詳細はホテルホームページをご確認ください。",
      "3752": "こちらの抽選イベントは受付期間外です。詳細はホテルホームページをご確認ください。",
      "3753": "こちらの抽選イベントは受付期間外です。詳細はホテルホームページをご確認ください。",
      "3754": "こちらの抽選イベントは受付期間外です。詳細はホテルホームページをご確認ください。",
      "3756": "すでにこの抽選に応募しています。 「予約一覧」をクリックし、メンバープロフィールページの「抽選」にてエントリーをご確認ください。",
      "3757": "こちらの抽選イベントは受付期間外です。詳細はホテルホームページをご確認ください。",
      "3760": "こちらの抽選イベントは受付期間外です。詳細はホテルホームページをご確認ください。",
      "3780": "リンクの有効期限が切れているか、無効になっています。ホテルサイトを再表示し、抽選イベントにアクセスしてください。",
      "3781": "リンクの有効期限が切れているか、無効になっています。ホテルサイトを再表示し、抽選イベントにアクセスしてください。",
      "3782": "リンクの有効期限が切れているか、無効になっています。ホテルサイトを再表示し、抽選イベントにアクセスしてください。",
      "3783": "リンクの有効期限が切れているか、無効になっています。ホテルサイトを再表示し、抽選イベントにアクセスしてください。"
    },
    "sessionExpired": "セッションの有効期限が切れました。続行するにはページを更新してください。",
    "notAvailableYet": "この操作はまだ利用できません。後ほどもう一度お試しください。",
    "fileLimit80MB": "アップロードする画像は1枚あたり80MBが上限です",
    "upTo30FilesError": "添付ファイルの上限は30ファイルです",
    "upTo30Files": "最大30ファイル",
    "textLengthLimit": "255字以内ご使用できます。",
    "triplaCoLtd": "tripla株式会社",
    "triplaAi": "tripla.ai",
    "GDPR": "GDPRポリシー",
    "here": "こちら",
    "imageError": "画像の読み込みに失敗しました。",
    "join": "会員登録",
    "languages": {
      "en": "English",
      "ja": "日本語",
      "ko": "한국어",
      "zh_Hans": "简体中文",
      "zh_Hant": "繁體中文"
    },
    "loading": "確認中...",
    "login": "ログイン",
    "logout": "ログアウト",
    "logoutMessage": "すでにログアウトしています。",
    "signInStatusChangeMessage": "ログイン状態が変更されたので、検索画面に遷移します。",
    "memberSignIn": "会員ログイン",
    "memberSignUp": "会員様初回Web登録",
    "next": "次へ",
    "notSelected": "未選択",
    "noData": "データなし",
    "optional": "任意",
    "pay": "支払い",
    "previous": "戻る",
    "previousPage": "前のページ",
    "privacyPolicy": "個人情報の取扱い",
    "readMore": "続きを読む",
    "readLess": "省略する",
    "recommended": "おすすめ",
    "remove": "削除",
    "required": "必須",
    "requireMessage": "必須項目が空欄です。",
    "reservationStatuses": {
      "cancelled": "キャンセル済",
      "done": "予約完了"
    },
    "save": "保存",
    "signInUp": "ログイン/登録",
    "submit": "送信",
    "termError": "利用規約に合意してください。",
    "taxMessage": "2019年10月以降の宿泊については、10%消費税率ではなく、現在の8%消費税率前提での税抜価格が計算されて表示されております。大変お手数はございますが、10月以降のご予約については税抜金額ではなく、予約確認画面での税込価格をご確認ください。",
    "termsOfUse": "利用規約",
    "topPage": "トップページ",
    "update": "更新する",
    "success": "成功",
    "unsubscribe": "購読を解除する",
    "unsubscribeConfirmation": {
      "error": "エラーが発生しました。もう一度お試しください。",
      "title": "メールを配信停止しますか？",
      "description": "プロモーションメールの購読を解除したい場合は、ボタンをクリックしてください。"
    },
    "unsubscribeSuccessDescribe": "購読を解除しました。 今後、プロモーションメールは送信されません。",
    "warning": {
      "kidsChanged": "この施設ではご指定の検索条件に対応していないため、子供の検索条件が変更されました。"
    },
    "formalName": "{name} 様"
  },
  "roomLottery": {
    "updateLottery": "抽選応募を変更する",
    "restoreLottery": "抽選を再応募する",
    "updateDisabledDesc": "応募期間は終了しました。抽選結果をお待ちください。",
    "viewUpdateDetails": "詳細確認/変更",
    "restoreUpdateEntry": "変更/再応募する",
    "updateProfileDesc": "当選・ご予約後は上記の情報が適用され、変更は出来かねます。",
    "cancellationEmailDesc": "キャンセルメールを{0}に送信しました。",
    "cancelModalTitle": "抽選応募をキャンセルする",
    "cancelModalDesc": "抽選応募をキャンセルしますか？この操作は取り消すことができません。",
    "cancelEntry": "応募をキャンセルする",
    "reservationDeadlinePassed": "予約締切日を過ぎました",
    "reservationDeadlinePassedDesc": "申し訳ございませんが、予約締切日はすでに過ぎております。詳しくはホテルホームページをご覧いただくか、直接お問い合わせください。",
    "accountNotMatched": "抽選応募時のアカウントと一致しません。 正しいアカウントでログインしてください。",
    "resultDateNotice": "抽選結果は{date} ({day}) {time} ~に発表されます。",
    "resultDateNotice02": "当選された方にのみ、改めてご予約手続きのご案内メールをお送りいたします。",
    "resultDateNotice03": "予約状況は会員メニューの「予約一覧」でいつでもご確認いただけます。",
    "lotteryEntryNumber": "応募受付番号",
    "lotteryEntryConfirmed": "抽選応募申込が確定しました",
    "lotteryEntryCancelled": "抽選応募がキャンセルされました",
    "lotteryResultNotSelected": "厳正な抽選の結果、あいにく落選となりました。",
    "notSelectedNotice": "ご参加いただきありがとうございました。次回お会いできることを楽しみにしています。",
    "lotteryResultSelectedNotified": "🎉 厳正な抽選の結果、当選いたしました。",
    "lotteryWinnerNotice01": "※まだ予約は確定しておりません。本予約のお手続きをお願いします",
    "lotteryWinnerNotice02": "本予約受付期限 {date} ({day}) {time} まで",
    "manageYourLottery": "抽選を管理する",
    "reservationIncomplete": {
      "errorMessage": "他にも予約処理が行われています。予約を継続すると、他の予約処理はキャンセルします。",
      "checkboxMessage": "他の予約処理のキャンセルに同意します。",
      "modalMessage": "他の予約処理が既に完了しているので、予約情報を表示します。"
    }
  },
  "bookingDetail": {
    "overlappedReservation": "現在ご予約中のチェックイン・チェックアウト日と重複する{0}があります。ご予約前に再度ご確認ください。\n※重複している予約は、他施設からの予約の場合がございます。",
    "overlappedReservationLink": "ご予約",
    "overlappedReservationGuest": "現在ご予約中のチェックイン・チェックアウト日と重複するご予約があります。ご予約前に受信したメールを再度ご確認ください。\n※重複している予約は、他施設からの予約の場合がございます。\n※他のデバイスで予約をキャンセルした場合は、この検出が間違っている可能性があります。受信したメールをご確認ください。",
    "overlappedReservationGuest2": "※他のデバイスで予約をキャンセルまたはCookieをクリアにした場合は、この検出が間違っている可能性があります。受信したメールをご確認ください。"
  },
  "bookingResult": {
    "allUser": "全ユーザー",
    "availableBetween": "対象期間",
    "bath": "風呂",
    "shower": " シャワー",
    "bathtub": "バスタブ",
    "bedCount": "{n}台",
    "book": "予約する",
    "bookAirline": "航空券付で予約する",
    "flightRooms": "航空券付き",
    "bookAirlineWarning": "{0}宿泊プランは、{1}のチェックイン日のみご利用いただけます。このプランを利用する場合はチェックイン日を調整してください。",
    "bookAirlineWarningStay": "{0}宿泊プランは最大{1}日間の滞在期間のみご利用いただけます。このプランを利用する場合は滞在期間を調整してください。",
    "cancellationFreeUntil": "{0}まで(現地時間) はキャンセル料がかかりません。",
    "cancellationPolicy": "キャンセルポリシー",
    "discountAmount": "割引額",
    "excludeDuring": "除外期間",
    "forNights": "{n}泊の料金",
    "freeWifi": "WiFi無料",
    "followingSearchConditions": "こちらの日程では空室がございます。",
    "guests": "ご利用人数",
    "includes": "このプランには以下のものが含まれます",
    "planInformation": "このプランには以下のものが含まれます",
    "lowestPriceInfo": "在庫状況や割引条件により、最低価格がカレンダーに表示されない場合があります。実際の価格はカレンダーよりご確認ください。",
    "maxAdults": "大人は最大{n}名様までご利用いただけます",
    "meals": "食事",
    "memberExclusive": "会員限定",
    "membersOnly": "会員限定",
    "nonSmoking": "禁煙",
    "noRoomFound": "空室が見つかりませんでした。",
    "tryChanging": "{0}を変更のうえ、再検索してください。",
    "tryItem": "ご希望条件",
    "noRoomSelected": "部屋は未選択です",
    "noRoomsAvailable": "空室が見つかりませんでした。",
    "noRoomsAvailableDesc": "ご希望条件を変更のうえ、再検索してください。",
    "notAvailable": "予約不可",
    "priceFor": "合計金額",
    "priceForAdult": "大人 × {n}名",
    "priceForChild": "子供 × {n}名",
    "priceForRoom": "部屋",
    "paymentCreditCard": "クレジットカードで事前決済",
    "paymentMethods": "支払い方法",
    "paymentMethod": {
      "on_site": "現地決済",
      "credit_card": "クレジットカードで事前決済",
      "on_site_credit_card_required": "現地決済（事前のクレジットカード登録が必要です）",
      "deferred_payment": "後払い",
      "credit_card_full": "クレジットカードでの全額支払い",
      "credit_card_deposit": "クレジットカードでのデポジット支払い",
      "bank_transfer": "銀行振込",
      "credit_card_krw_domestic": "オンライン決済（韓国国内クレジットカード）",
      "credit_card_krw_international": "オンライン決済（国際クレジットカード）"
    },
    "paymentNewCreditCards": "クレジットカードで事前決済",
    "paymentRegisteredCreditCards": "登録しているクレジットカードで事前決済",
    "planDetails": "プラン詳細",
    "plansLimitForUpdating": "元々選択しているプランからは変更できません。",
    "price": "金額",
    "promotionMayChanged": "プロモーションは予告なく、変更または終了する場合があります。",
    "registerCard": "カードを登録する",
    "regularRate": "通常価格",
    "reservationStartDate": "予約開始日",
    "showRoomDetails": "部屋の詳細を表示",
    "roomDetails": "お部屋の詳細",
    "roomFeatures": "お部屋の情報",
    "roomLeft": "残り{n}部屋！",
    "roomAvailable": "残り{n}部屋",
    "roomEnough": "空室あり",
    "roomRate": "料金",
    "rooms": "部屋数",
    "roomSize": "部屋サイズ",
    "roomSizeUnit": {
      "tatami_mats": "畳"
    },
    "select": "選択する",
    "selectNewDate": "別の日程で検索",
    "serviceFeeDesc": "この価格は宿泊予約サイトの各サービス料を含んだ額になります。",
    "sleeps": "利用人数",
    "smoking": "喫煙可",
    "stopReservationUpdate": "予約変更をやめる",
    "stopLotteryEntryRestore": "抽選再応募をやめる",
    "stopLotteryEntryUpdate": "抽選応募変更をやめる",
    "targetUser": "対象者",
    "taxExcluded": "税抜",
    "taxIncluded": "税込",
    "toilet": "トイレ",
    "totalPrice": "円",
    "totalPriceForNights": "{n}泊の合計料金 | {n}泊の合計料金 | {n}泊の合計料金",
    "viewAll": "全て表示",
    "viewClose": "閉じる",
    "viewGallery": "全ての写真を表示する",
    "viewLess": "元に戻す",
    "viewMore": "全てのプランを表示する ({n}件)",
    "viewAllRooms": "{n}部屋すべてを表示",
    "wifi": "WiFi",
    "wifiOption1": "なし",
    "wifiOption2": "無料",
    "wifiOption3": "有料",
    "searchResults": "検索結果:",
    "nPlans": "{n} 件",
    "nRoomTypes": "{n} 件"
  },
  "customAuth": {
    "failTitle": "ログインに失敗しました。",
    "failDesc": "会員情報が登録されていないので、ログインできません。"
  },
  "cancellationPolicy": {
    "policyHeader": "キャンセル料は下記のとおりです。",
    "policyLine": "宿泊日{0}日前　 料金の{1}％",
    "policyLineWithTime": "宿泊{0}日前 {1}以降 宿泊料の{2}％",
    "freePolicyLine": "宿泊日{0}日前　 キャンセル：無料",
    "noShowLine": "ご連絡なしの場合：料金の{0}％",
    "cancelOnTheDay": "宿泊当日 料金の{0}％",
    "cancelOnTheDayFree": "宿泊日当日前{0}まで  キャンセル：無料",
    "cancelOnTheDayCharge": "宿泊当日{0}以降　宿泊料の{1}％",
    "uponLocalTime": "*すべてのキャンセル ポリシーは、現地時間に基づいて計算されます。",
    "uponLocalTimeOTA": "すべてのキャンセル ポリシーは、現地時間に基づいて計算されます。",
    "ifBookedElsewhere": "他社サイトや旅行会社経由のご予約のキャンセルについては、各サイト・旅行会社にお問合せください。",
    "specialRate": "特別キャンセルフィー: {0}",
    "guest": "お客様"
  },
  "calendar": {
    "fullyBooked": "満室",
    "roomsLeft": "残り10部屋未満",
    "selected": "ご宿泊予定期間",
    "unavailable": "残室なし",
    "unavailableNow": "現在予約できません"
  },
  "corporateAdmin": {
    "admin": "管理者",
    "checkInDate": "チェックイン日",
    "download": "ダウンロード",
    "guestName": "予約者名",
    "hotelName": "ホテル名",
    "maxNumberStay": "最大宿泊数",
    "maxNumberStays": "最大宿泊数",
    "nMonths": "{n}ヶ月",
    "nights": "宿泊日数",
    "numberOfRooms": "部屋数",
    "remaining": "宿泊可能数",
    "reservationData": "予約情報",
    "reservationDataDesc1": "※ ご利用内訳は、当専用ホームページでご予約いただいた方の合計での宿泊数を表示しております。従いまして、これ以外の予約（電話等）分につきましては、反映されておりませんので、ご注意ください。",
    "reservationDataDesc2": "※ ご予約・ご宿泊いただいてから、その内容が当ページに反映されるまで、数分〜数十分の時間がかかることがあります。あらかじめご了承ください。",
    "reservationDataDesc3": "※ 当専用ホームページ以外でご依頼された宿泊内容の変更・キャンセルはこの画面には反映されておりません。",
    "reservationDate": "予約日",
    "reservationNumber": "予約番号",
    "reserved": "宿泊済数",
    "status": "ステータス",
    "title": "法人管理者",
    "totalPrice": "料金",
    "period": "期間",
    "year": "年"
  },
  "creditCardForm": {
    "enterYourPaymentDetails": "お支払い情報の詳細を入力してください",
    "addNewCard": "新しいクレジットカードを入力",
    "cardExpiration": "有効期限",
    "cardExpirationConfirm": "有効期限をご確認ください",
    "cardHolder": "カード名義",
    "cardHolderConfirm": "カード名義をご確認ください",
    "cardHolderFormatError": "名前は半角のローマ字で入力してください",
    "cardHolderHelpOTA": "カードに印字されている名前をご記入ください",
    "cardHolderHelp": "カードに印字されている名前をご記入ください",
    "cardNumber": "カード番号",
    "cardNumberConfirm": "カード番号をご確認ください",
    "cardNumberHelp": "カード番号をご記入ください",
    "cardRequired": "カード情報は予約確保を目的とし、直ちに課金されることはありません。キャンセル料が発生した場合のみ、{0}に従ってカードに課金されます。",
    "cardSecurityCode": "セキュリティコード",
    "cardSecurityCodeConfirm": "セキュリティコードをご確認ください",
    "cardSecurityCodeHelp": "CVV",
    "ccvExampleOTA": "カードの裏面(Visa、Mastercard、JCB、Diners Club) または カードの表面 (American Express)にある 3 桁または 4 桁の番号。",
    "ccvExample": "カードの裏面（Visa、Mastercard、JCB）または表面（American Express）に印刷された3桁または4桁の数字です。ネットショッピングの際に、お客様が正規のカード所有者であることを確認し、不正行為を防止するために使用されます。",
    "creditCard": "クレジットカード",
    "expiredError": "チェックアウト日まで有効なクレジットカードを登録してください。",
    "month": "月",
    "payInAdvanceOTA": "こちらのお支払方法は、{0}によるクレジットカード事前決済となります。",
    "payInAdvance": "こちらのお支払方法は、{0}による手配・クレジットカードによる事前決済となります。",
    "payInAdvanceVoucher": "こちらのお支払方法は、{0}によるクレジットカード事前決済となります。",
    "payByCreditCard": "予約成立時に旅行代理店（{0}）によって決済されます。",
    "payByCreditCardConsent": "お客様の個人情報は、旅行代理店であるtripla株式会社と共有されます。この予約を続行することで、{0}、{1}、および {2} に同意します。",
    "tappayNote": "TapPay 2048-bit SLL トランザクションシステムを使用して、クレジットカード情報を保護します。カードの顧客情報は保存されません。 TapPayは、PCI DSSのサービスプロバイダーレベル1に準拠していると認定されています。",
    "year": "年",
    "cardBrand": "カードブランド",
    "cardBrandMismatch": "入力したカード番号が、選択したカードブランドと一致しません。セキュリティ上の理由から修正してください。ご不便をおかけして申し訳ございません。",
    "paymentOverMillionError": "100万円を超えるオンライン決済は、VISA, Masterカードではご利用いただけません。他のカードブランドをご利用いただくか、他の支払タイプを選択してください。",
    "badCards": "システム変更のため、以下のカードは新規のご予約にはご利用いただけません。お手数ですが、再度カード情報を入力してください。",
    "gmoBadCards": "システム変更のため、会員様の「クレジットカードの登録/保存」できる機能を停止いたします。\nそのため、予約時に事前カード決済をする場合は、都度カード番号を入力して決済いただく必要がございます。\n大変ご不便をおかけいたしますが、何卒よろしくお願いいたします。",
    "pontaVisaCheckbox": "ルートインホテルズPontaVISAカードをご利用の場合は、カードブランド欄で「ルートイン\nホテルズPontaVISAカード」を選択してください。「VISA」を選択すると、Pontaボーナスポイントが加算されません。",
    "creditCardInvalid": "無効",
    "creditCardInvaildHoverText": "システム変更のため、以下のカードは新規のご予約にはご利用いただけません。お手数ですが、再度カード情報を入力してください。",
    "rrn": "生年月日（YYMMDD）",
    "firstTwoDigitOfPin": "暗証番号の上2桁"
  },
  "gmoPaypalOptions": {
    "null": "カードブランドを選択してください",
    "visa": "VISA",
    "ponta_visa": "VISA（ルートインホテルズPontaVISAカード）",
    "master": "Mastercard",
    "mastercard": "Mastercard",
    "amex": "American Express",
    "jcb": "JCB",
    "diners": "Diners Club"
  },
  "downloadPasswordModal": {
    "label": "暗号化のためのパスワードを入力してください。ダンロードしたzipファイルはこのパスワードで復元してください。"
  },
  "extraItems": {
    "error": {
      "extraNumber": "{name} の数を変更してください。予約可能な数は、{min}〜{max}です。"
    }
  },
  "facilitySuggestion": {
    "distanceFrom": "距離: この施設から{n}km",
    "from": "以下の最安値より",
    "header": "宿泊可能な近隣の施設",
    "soldOut": "空室が見つかりませんでした。ご希望条件を変更のうえ、再検索してください。",
    "soldOutDesc": "申し訳ございません。ご指定の条件ではご予約いただけるお部屋はございません",
    "visitHotelWebsite": "施設のウェブサイトを開く"
  },
  "guestDetail": {
    "afterUpdate": "変更後",
    "bookingForSomeone": "代理で予約している場合、宿泊者本人ではない場合はこちらをチェック",
    "brrDiscount": "最低価格保証割引",
    "confirmUpdates": "予約変更を確定",
    "confirmPayByCard": "クレジットカード支払いの確認",
    "coupon": "クーポン",
    "couponApplied": "適用済み",
    "couponAppliedNew": "適用されたクーポン:",
    "creansmaerdCouponApplied": "{name}が適用されています。",
    "couponCode": "クーポンコード",
    "couponDiscount": "クーポン割引",
    "couponExpired": "このクーポンコードは有効ではありません。",
    "couponInvalid": "入力されたクーポンコードが間違っています。",
    "couponLimitReached": "ご利用上限に達したため、このクーポン {code}はご利用いただけなくなりました。",
    "couponMaxStays": "このクーポンの割引は、最初の{n}泊までご利用いただけます。 {n}泊以降の宿泊は、通常料金が請求されます。",
    "couponTargetPlans": "クーポンコード適用対象プラン",
    "couponNotUse": "クーポンを利用しない",
    "couponValidUntil": "有効期限 {date}",
    "couponUsageQuantityLimit": "一度に適用できるクーポンコードは1つのみです。新しいコードを適用するには、既に追加されているコードを削除してください。",
    "creditCardWarning": "当初の予約のクレジットカード決済はキャンセル/払い戻しとなり、予約変更後の価格で新規の決済となります。",
    "availableCoupons": "利用可能なクーポン",
    "viewMoreCoupons": "クーポンをもっと見る",
    "removeCoupon": "キャンセル",
    "day": "{n}日目",
    "email": "メールアドレス ({email}指定受信の設定をお願いします)",
    "emailAgain": "メールアドレス(確認用)",
    "emailAgainConfirm": "確認用のメールアドレスが一致しません",
    "emailAgainHelp": "もう一度メールアドレスを入力してください",
    "emailConfirm": "正しいメールアドレスを入力してください",
    "emailHelp": "メールアドレスを入力してください",
    "extras": "オプション",
    "extraLeft": "残り{count} {unit}",
    "extraOutOfStock": "在庫なし",
    "fullName": "お名前（ひらがな）",
    "fullNameError": "お名前をひらがなで入力してください",
    "goPreviousScreenConfirm": "前の画面に戻りますか?",
    "goPreviousScreenDescription": "現在のページで入力した情報は保存されませんのでご注意ください。",
    "goPreviousScreenContinue": "続行する",
    "guestDetails": "お客様情報",
    "guestName": "宿泊者名",
    "guestNameError": "お名前はひらがな、または、アルファベットで入力してください",
    "guestNameHelp": "ご氏名を全てひらがなで入力してください",
    "guestNameKanji": "宿泊者名 (漢字)",
    "guestStayName": "宿泊者名",
    "mainGuest": "代表者の連絡先",
    "notes": "ご注意",
    "originalPaymentAmount": "元の予約のお支払い金額",
    "promotionDiscount": "割引",
    "phone": "電話番号",
    "phoneConfirm": "正しいお電話番号をご入力下さい",
    "phoneHelp": "電話番号を入力してください(半角数字)",
    "priceBecomeCheaper": "現在選択中のプランに適用されているプロモーションの内容が宿泊施設によって変更されたため、お値段がお安くなりました。現在の価格をご確認の上、予約を進めてください。",
    "priceBecomeExpensive": "申し訳ございませんが、現在選択中のプランに適用されているプロモーションの内容が宿泊施設によって変更されたため、お値段が高くなりました。現在の価格をご確認の上、予約を進めてください。",
    "priceDiff": "価格差異",
    "signupTerms": "本予約システムはtripla(株)により提供されており、お客様の個人情報はtripla(株)と共有されることを理解の上で、tripla{0}、{1}、および、{2}に同意の上、会員登録を行います。",
    "terms": "お客様の個人情報は、本予約システムを提供しているtripla株式会社と共有されることをご理解の上で、本予約の完了をもって、tripla {0}、{1}、および、{2}に同意します。",
    "totalAmountWithTax": "合計金額（税込）",
    "totalWithoutTax": "合計金額（税抜）",
    "updateUserInformation": "上記の内容でWEB会員情報を更新する（メールアドレスを除く）",
    "cheaperAccommodationTax": "ポイント還元により、ご予約の宿泊税が安くなりました。最新の料金をご確認の上、ご予約を進めてください。",
    "roomGuestName": "{0} 部屋目のお客様名"
  },
  "inventoryCalendar": {
    "availableIconDescription": "{icon}: 空室あり",
    "unavailableIconDescription": "{icon}: 空室なし",
    "uncertainIconDescription": "{icon}: 残りわずか"
  },
  "lineLogin": {
    "buttonText": "LINEでログイン"
  },
  "meta": {
    "facilities": "ブランド施設",
    "sign_in": "ログイン",
    "organization_sign_in": "法人ログイン",
    "sign_up": "会員登録",
    "search_results": "宿泊プラン一覧",
    "guest_detail": "お客様情報入力",
    "payment_method": "お支払い情報入力",
    "lotteryCompleted": "抽選応募完了",
    "completed": "予約完了",
    "reservationIncomplete": "未完了の予約",
    "my_account": "マイページ",
    "otaHome": "AIを活用した検索でホテルを賢く選ぶ",
    "otaHomeDescription": "AI検索を搭載したtriplaで、2000軒以上の厳選された宿泊施設を探そう。伝統的な旅館からラグジュアリーなリゾートホテルまで、あなたにぴったりの宿泊施設が簡単に見つかります。",
    "otaSearchHotels": "施設の検索結果",
    "otaSearchResults": "宿泊プラン一覧",
    "otaGuestDetail": "お客様情報入力",
    "otaPayment": "お支払い情報入力",
    "otaConfirmation": "予約完了",
    "otaBookingConfirmation": "予約確認"
  },
  "membership": {
    "promotionRate": "プロモーション内容",
    "regularRate": "標準価格",
    "signUpFor": "会員価格"
  },
  "membershipForm": {
    "address": "住所",
    "alreadyHaveAccount": "すでに登録済の方",
    "back": "戻る",
    "buildingName": "建物名・部屋番号",
    "changePassword": "パスワード再設定",
    "changePasswordResult": "パスワード再設定",
    "changePasswordResult02": "✓ お客様のパスワードの再設定が完了しました。",
    "changePasswordResult03": "こちらからログインしてください。",
    "changePasswordSecondary": "パスワードを入力してください。",
    "confirmDeletedResult": "退会処理が完了しました。",
    "confirmNewPassword": "新しいパスワード（確認用）",
    "confirmPassword": "パスワード確認",
    "confirmRegisterFail": "登録失敗",
    "confirmRegisterFail02": "お手数ですが、もう一度登録手続きをお願いいたします。",
    "confirmRegisterResult": "メールアドレス確認完了",
    "confirmRegisterResult02": "✓ 登録が完了しました",
    "confirmRegisterResult03": "滞在をお楽しみください",
    "createAccount2": "会員登録する",
    "createAccount3": "会員登録",
    "dob": "生年月日",
    "email": "メールアドレス",
    "emailError": "メールアドレスをご確認ください",
    "emailPromotionCheck": "施設からプロモーションメールを受信する。",
    "english_error": "英語で入力ください",
    "locale_error": {
      "ja": "英語で入力ください",
      "zh_Hant": "英語で入力ください",
      "ko": "英語で入力ください"
    },
    "enterDetail": "メールアドレスとパスワードを入力してください",
    "first_name": "名",
    "firstNameLocales": {
      "ja": "名",
      "zh_Hant": "名(アルファベット)",
      "ko": "名(アルファベット)"
    },
    "first_name_kanji": "名",
    "first_name_kanji_placeholder": "太郎",
    "first_name_placeholder": "たろう",
    "firstNamePlaceHolderLocales": {
      "ja": "たろう",
      "zh_Hant": "Tarou",
      "ko": "Tarou"
    },
    "firstName": "名",
    "firstNameError": "名を入力してください",
    "firstNameKana": "めい",
    "forgotPassword": "パスワードをお忘れの方",
    "forgotPassword02": "パスワード再設定",
    "forgotPasswordError": "ご入力いただいたメールアドレスの登録がありません。",
    "forgotPasswordResult": "パスワード再設定",
    "forgotPasswordResult02": "✓ パスワード再設定メールを送信しました。",
    "forgotPasswordResultSecondary": "メールの配信には時間がかかる場合があります。10分ほど経過してもメールが配信されない場合には、下記の再送信ボタンをクリックして、再設定メールを再送してください。",
    "forgotPasswordSecondary": "パスワードの再設定方法を送信いたします。登録しているメールアドレスを入力してください。なお、送信元アドレスは、{email}です。",
    "gender": "性別",
    "generalError": "申し訳ございません、処理中にエラーが発生しました。少し時間を空けてから、再度お試しください。",
    "guest_first_name": "めい（ひらがな）",
    "guest_first_name_kanji": "名（漢字）",
    "guest_last_name": "せい（ひらがな）",
    "guest_last_name_kanji": "姓（漢字）",
    "header": "会員登録",
    "hiragana_error": "ひらがなで入力してください。",
    "kanji_katakana_error": "漢字、ひらがな、カタカナ、英語を入力してください。",
    "kanji_error": "漢字・ひらがな・カタカナで入力してください。",
    "last_name": "姓",
    "lastNameLocales": {
      "ja": "姓",
      "zh_Hant": "姓(アルファベット)",
      "ko": "姓(アルファベット)"
    },
    "last_name_kanji": "姓",
    "last_name_kanji_placeholder": "山田",
    "last_name_placeholder": "やまだ",
    "lastNamePlaceHolderLocales": {
      "ja": "やまだ",
      "zh_Hant": "Yamada",
      "ko": "Yamada"
    },
    "lastName": "姓",
    "lastNameError": "姓を入力してください。",
    "lastNameKana": "せい",
    "membershipId": "会員番号",
    "membershipProgram": "会員名称",
    "name": "名前",
    "nameJaPlaceholder": "漢字、ひらがな、カタカナ、英語を入力",
    "nameJaValidation": "漢字、ひらがな、カタカナ、英語を入力してください。",
    "nameInvalidCharMessage": "入力した文字には、システムでサポートされていない文字が含まれています。",
    "nameKanaJaPlaceholder": "ひらがなを入力",
    "namePlaceholder": {
      "en": "英語を入力",
      "ja": "ひらがな、英語を入力",
      "zh_Hant": "漢字、英語を入力",
      "zh_Hans": "漢字、英語を入力",
      "ko": "ハングル、英語を入力"
    },
    "nameValidation": {
      "ja": "ひらがな、英語を入力してください。",
      "zh_Hant": "漢字、英語を入力してください。",
      "zh_Hans": "漢字、英語を入力してください。",
      "ko": "ハングル、英語を入力してください"
    },
    "nationality": "国籍",
    "newPassword": "新しいパスワード（英大文字・英小文字・数字それぞれを最低1文字ずつ含み、8文字以上にする必要があります。）",
    "noAccount": "会員登録がお済みではない方",
    "occupation": "ご職業",
    "or": "または",
    "orContinueBelow": "または以下をご記入ください。",
    "password": "パスワード",
    "passwordConfirmationError": "パスワードが一致しません。",
    "passwordError": "パスワードをご確認ください",
    "passwordSignUp": "パスワード（英大文字・英小文字・数字それぞれを最低1文字ずつ含み、8文字以上にする必要があります。）",
    "passwordNote": "英大文字・英小文字・数字それぞれを最低1文字ずつ含み、8文字以上にする必要があります。",
    "pleaseSelect": "会員名称を選択してください",
    "postCode": "郵便番号",
    "postCodeError": "アルファベットと数字のみ入力出来ます。",
    "prefecture": "都道府県",
    "notApplicable": "海外のため該当しない",
    "privacyPolicy": "プライバシーポリシー",
    "promotionsCheckbox": "emailでキャンペーンなどの情報を受け取る",
    "resend": "再送信",
    "resendConfirmationEmail": "会員登録確認メールが届かなかった場合",
    "resendConfirmationEmailError": "このメールアドレスは会員登録に利用されていません。",
    "resendConfirmationEmailTitle": "会員登録確認メール再送",
    "receiptName": "領収書の宛名",
    "receiptUnicode": "",
    "send": "送信",
    "setPassword": "設定",
    "setup": "会員登録",
    "setupResultSecondary01": "再度会員登録を行う場合、 {0} から再登録を行ってください。",
    "setupResultSecondary02": "ご登録いただいたメールアドレスの確認のためにメールを送付しました。\n現在は仮登録の状態なので、会員登録を完了するためにメールに記載のURLにアクセスしてください。\nその後ログイン可能となります。",
    "signIn": "ログイン",
    "signInHere": "ログイン",
    "signInTo": "{0}にログイン",
    "signUp": "会員登録",
    "signUpResult": "確認メールを送信しました",
    "signUpResult02": "仮登録が完了しました",
    "signUpResult02Secondary": "{n}の会員登録にお申し込みいただき、ありがとうございます。",
    "signUpResult02Secondary02": "ご登録いただいたメールアドレスの確認のためにメールを送付しました。\n現在は仮登録の状態なので、会員登録を完了するためにメールに記載のURLにアクセスしてください。\nその後ログイン可能となります。",
    "signUpResultSecondary": "{n}の会員登録にお申し込みいただき、ありがとうございます。",
    "signUpResultSecondary02": "ご登録いただいたメールアドレスの確認のためにメールを送付しました。",
    "signUpResultSecondary03": "内容をご確認の上、記載されているURLにアクセスしてください。",
    "signUpResultSecondary04": "メールアドレスの確認後に、会員登録が完了します。",
    "signUpSecondary": "会員登録はすぐに完了します。",
    "term": "{0} および {1} に同意します。",
    "termError": "利用規約に合意してください。",
    "termOfUse": "利用規約",
    "wifi": "WiFi無料",
    "yetReceived": "メールが届かない場合、{0}",
    "resendEmail": "再送します",
    "pleaseResetPassword": "このメールアドレスは本登録済みのため、確認メールの再送は必要ありません。\nパスワードがわかる方は、「{0}」にお進みください。\nパスワードがわからない方は、「{1}」より再設定ください。\n",
    "pleaseResetPasswordLink1": "会員ログイン",
    "pleaseResetPasswordLink2": "パスワードをお忘れの方",
    "receiptNameFormatError": "",
    "receiptUnicodeFormatError": "",
    "receiptNameRequiredError": "",
    "receiptUnicodeRequiredError": ""
  },
  "membersihp": {
    "target": "対象会員"
  },
  "multipleCurrency": {
    "JPY": "日本円",
    "ARS": "アルゼンチン・ペソ",
    "AUD": "オーストラリア・ドル",
    "BRL": "ブラジル・レアル",
    "CAD": "カナダ・ドル",
    "CNY": "中国元",
    "CZK": "チェコ・コルナ",
    "DKK": "デンマーク・クローネ",
    "EUR": "ユーロ",
    "HKD": "香港ドル",
    "INR": "インド・ルピー",
    "IDR": "インドネシア・ルピア",
    "ILS": "新イスラエル・シェケル",
    "MOP": "マカオ・パカタ",
    "MYR": "マレーシア・リンギット",
    "MXN": "メキシコ・ペソ",
    "TWD": "ニュー台湾ドル",
    "NZD": "ニュージーランド・ドル",
    "NOK": "ノルウェー・クローネ",
    "PHP": "フィリピン・ペソ",
    "GBP": "イギリス・ポンド",
    "QAR": "カタリ・リヤル",
    "RUB": "ロシア・ルーブル",
    "SAR": "サウジアラビア・リヤル",
    "SGD": "シンガポール・ドル",
    "ZAR": "南アフリカ・ランド",
    "KRW": "ウォン",
    "LKR": "スリランカ・ルピー",
    "SEK": "スウェーデン・クロナ",
    "CHF": "スイス・フラン",
    "THB": "タイ・バーツ",
    "AED": "U.A.E ディルハム",
    "USD": "米ドル",
    "VND": "ベトナム・ドン",
    "modalTitle": "通貨選択",
    "modalDescription": "通貨を選択してください。日本円以外を選択すると、支払方法はクレジットカードに限定されます。",
    "suggestedCurrencies": "よく使われている通貨",
    "allCurrencies": "全ての通貨",
    "creditCardWarning": "支払い方法の変更により、支払通貨が変更されました。金額をご確認ください。",
    "exchangeRateUpdatedError": "為替レートが更新されたので、最新の価格を確認して、予約してください。",
    "currency": "通貨"
  },
  "myAccount": {
    "availablePoints": "利用可能{0}",
    "availablePointsDesc": "現在ご利用可能な{0}の合計です。",
    "totalRankingPoints": "ランクアップ対象の通算獲得{pointName}は、{amount} {pointName} です。",
    "changeLoginEmail": "メールアドレス変更",
    "changeLoginEmailDescription": "メールアドレスを変更すると、新しいメールアドレスに確認メールが送信されます。メールに記載されているURLをクリックすると変更が完了します。メールアドレスを間違えた場合には、再変更すると、最後に変更したメールアドレスに確認メールが配信されます。",
    "changeLoginEmailPendingText": "新しいメールアドレスは確認中の状態です。更新時に配信されたメールに記載されているURLをクリックすると反映されます。",
    "changePassword": "パスワード再設定",
    "confirmLoginEmail": "新しいメールアドレス（確認中）",
    "edit": "編集",
    "emailLabel": "メールアドレス",
    "loginEmail": "メールアドレス",
    "loginInfo": "ログイン情報",
    "notRegistered": "登録済のカードはありません",
    "pageTitle": "アカウント",
    "password": "パスワード",
    "passwordLabel": "パスワード",
    "paymentInfo": "お支払い情報",
    "paymentOptions": "お支払い方法",
    "paymentOptionsDesc": "お支払いカード情報を保存すると、今後のサービスをさらに便利にご利用いただけます。\nクレジットカードに関するすべての情報は厳重に管理されます。",
    "pendingEmailText": "{n} メールアドレス確認中",
    "personalInfo": "個人情報",
    "promotions": "クーポン",
    "point": "ポイント",
    "removeAccount": "退会",
    "removeAccountConfirmationText": "本当によろしいですか？",
    "removeAccountReason01": "もう利用しないため",
    "removeAccountReason02": "宿泊費が高いため",
    "removeAccountReason03": "会員特典に魅力がないため",
    "removeAccountReasonInput": "理由を入力してください",
    "removeAccountReasonOthers": "その他",
    "removeAccountSecondaryText": "退会すると全ての会員特典が無効となります。",
    "removeAccountSecondaryText02": "また予約情報も表示できなくなります。",
    "removeCreditCard": "カードを削除する",
    "removeCreditCardConfirm": "登録されているカードを削除します。よろしいですか？",
    "resendEmail": "確認メールを再送信",
    "resendEmailSuccess": "確認メールを再送信しました。",
    "yourCreditCards": "登録カード",
    "membershipId": "会員ID",
    "myQRCode": "QRコード",
    "promotion": "プロモーション",
    "historyStatus": {
      "expired": "期限切れポイント",
      "future": "獲得予定ポイント",
      "spent": "利用ポイント",
      "gained": "獲得ポイント",
      "refunded": "払い戻しポイント"
    },
    "value": "値",
    "badCards": "システム変更のため、このカードは新規のご予約にはご利用いただけません。",
    "unlockRank": "{date}より前に、あと{points}{pointName}を獲得することで{rank}ランクになります。",
    "unlockRankNoDate": "あと{points}{pointName}を獲得することで{rank}ランクになります。",
    "earnMorePointsToKeepRank": "現在の{rank}ランクを保持するためには、{date}より前に、あと{points}{pointName}を獲得する必要があります。",
    "achievedHighest": "おめでとうございます！ 最高ランクに到達しました。",
    "achievedHighestOnDate": "おめでとうございます！条件を満たしたので、{date} に最高ランクに到達します。"
  },
  "myProfile": {
    "cancel": "予約をキャンセルする",
    "cancelled": "キャンセル済",
    "change": "変更",
    "checkIn": "チェックイン",
    "checkOut": "チェックアウト",
    "confirmationNo": "予約番号",
    "multiRoom": "複数の部屋タイプ",
    "myReservations": "予約一覧",
    "myPage": " マイページ",
    "noResultButtonText": "空室を検索する",
    "noResultText": "予約はまだありません。",
    "pageTitle": "アカウント情報",
    "viewDetails": "詳細確認",
    "viewFlightDetails": "航空券付き予約の詳細確認",
    "myVoucher": {
      "amount": "金額",
      "askConsume": "クーポンを利用しますか？",
      "askConsumeMessage": "このクーポンは一度しか使用できません。",
      "cancel": "キャンセル",
      "consume": "利用",
      "consumeVoucher": "クーポンの利用",
      "excludeDuring": "除外日",
      "makeReservation": "予約する",
      "targetStayingPeriod": "対象宿泊期間",
      "validThrough": "適用対象販売期間",
      "name": "名称",
      "consumptionTime": "利用期限",
      "reservationNumber": "予約番号",
      "status": {
        "status": "ステータス",
        "available": "利用可能",
        "pending": "保留中",
        "expired": "期限切れ",
        "consumed": "利用済"
      },
      "selectHotel": "ホテルを選択"
    },
    "lottery": {
      "status": {
        "registered": "進行中",
        "not_winner": "落選通知済み",
        "winner_reservation_confirmed": "当選(本予約済み)",
        "winner_reservation_cancelled": "予約キャンセル済み",
        "registration_cancelled": "抽選キャンセル済み",
        "winner_reservation_pending_declined": "抽選キャンセル済み",
        "winner_reservation_pending": "当選(未予約)"
      },
      "text": "抽選"
    },
    "processBooking": "予約する",
    "cancelEntry": "応募をキャンセルする",
    "lotteryEntryNo": "応募受付番号"
  },
  "organization": {
    "enterDetail": "お持ちのアカウントをID・パスワードに入力してください",
    "signIn": "法人ログイン"
  },
  "ota": {
    "changeLoginEmailPendingText": "新しいメールアドレスは確認中の状態です。更新時に配信されたメールに記載されているURLをクリックすると反映されます。",
    "name": {
      "agoda": "Agoda",
      "booking_com": "Booking com",
      "expedia": "Expedia",
      "jalan": "じゃらん",
      "rakuten_travel": "楽天トラベル",
      "rakutenTravel": "楽天トラベル"
    },
    "priceNotAvailable": "利用できません",
    "loadingTitle0": "完璧な滞在を見つける",
    "loadingDescription0": "AI を活用してあなたにぴったりのホテルを提案します。 夢の滞在を読み込み中...",
    "loadingTitle1": "日本文化体験",
    "loadingDescription1": "日本の伝統文化を体験してみませんか？茶道や華道を体験するなかで日本文化や都会の喧騒から離れた静けさを楽しみましょう。",
    "loadingTitle2": "知る人ぞ知る穴場スポット",
    "loadingDescription2": "あまり知られていない場所に行き、人里離れた場所の驚異を探索しましょう。 普通では味わえない街の魅力を堪能しましょう。",
    "loadingTitle3": "伝統芸能を知る",
    "loadingDescription3": "花街の魅力に浸ってください。 伝統舞踊を鑑賞したり、茶道に参加したりして、本物の文化に触れてみませんか。",
    "loadingTitle4": "絶品ご当地グルメ",
    "loadingDescription4": "さまざまな屋台や市場で、日本料理を堪能しましょう。新鮮なお寿司からおいしいたこ焼きまで、多様な味をお楽しみください。",
    "loadingTitle5": "自然の中での静けさ",
    "loadingDescription5": "都会の喧騒から逃れて、魅惑的な竹林を散策してみませんか。 歴史的な街の静けさと時代を超越した美しさを体験してください。",
    "loadingTitle6": "お花見",
    "loadingDescription6": "桜色に染まる絶景を見てみませんか？ 地元の人たちと一緒にお花見を楽しみましょう。",
    "fromBwToOtaPaymentError": "問題が発生しました。 「次へ」ボタンを押すか、別のお支払い方法を選択してもう一度お試しください。",
    "fromBwToOtaRestoreDataError": "問題が発生しました。 データを再入力し、もう一度「次へ」ボタンを押してください。",
    "searchHotels": "ホテルを検索する",
    "aiRecommendations": "AIのおすすめリスト",
    "recommendationsForU": "あなたへのおすすめ",
    "interestedIn": "こちらもおすすめ",
    "home": "ホーム",
    "showingCountAvailable": "{total} 件中{count}件表示中",
    "standardTravelAgency": "旅行業約款",
    "termsOfUse": "ユーザー規約",
    "privacyPolicy": "プライバシーポリシー",
    "companyProfile": "会社概要",
    "bookingSupport": "お問い合わせ",
    "search": "ホテルを検索する",
    "checkAvailability": "空き状況を確認",
    "avgPricePerNight": "1泊あたりの参考価格",
    "adults": "大人{n}名",
    "GDPR": "GDPRプライバシーポリシー",
    "homepage": {
      "recommend1": "週末旅行におすすめのリーズナブルなホテルを東京で探したい。",
      "recommend2": "沖縄で3人で泊まれるリゾートホテル探しを手伝ってほしい。",
      "recommend3": "妻の誕生日のため、京都の旅館を見つけたい",
      "recommend4": "来月、神戸に出張することになりました。 おひとり様に人気のホテルはありますか？",
      "placeholder": "例：新宿付近のホテルに2人で3泊予約したい。"
    },
    "bookingConfirmation": "予約確認",
    "confirmToOtaTitle": "予約完了までの流れ",
    "confirmToOtaDescription": "旅行代理店のサイト({0})へ遷移します。次のページで事前決済を完了することで、予約完了となります。",
    "confirmToOtaDescriptionInGuestForm": "旅行代理店のサイト({0})へ遷移します。次のページで事前決済を完了することで、予約完了となります。",
    "aiSearchNoResult": "当社のAIは現在学習中であり、お客様が入力されたキーワードではおすすめの情報が見つかりませんでした。場所、人数、設備など、より詳細な情報をご提供ください。また、以下のような宿泊施設もございます。"
  },
  "otaRoomsResult": {
    "noRoomsAvailable": "申し訳ございませんが、ご希望の条件を満たすお部屋がありません。",
    "noRoomsAvailableDesc": "別の日付を選択するか、宿泊人数を変更して、再度検索してください。",
    "location": "住所",
    "showAllPhotos": "すべての写真を表示"
  },
  "otaRoomsMap": {
    "title": "地図",
    "seeMap": "地図を表示"
  },
  "paymentMethod": {
    "paymentKRWCreditCardFooterTitle": "オンライン決済は、以下の会社によって処理されます:",
    "paymentKRWCreditCardFooterLine1": "tripla株式会社 (韓国支店)",
    "paymentKRWCreditCardFooterLine1CEO": "CEO: 高橋 和久",
    "paymentKRWCreditCardFooterLine2": "事業者登録番号: 538-84-00072",
    "paymentKRWCreditCardFooterLine3": "ソウル特別市鍾路区セムナン路 76 Concordian ビル3階(P. 03185)",
    "paymentKRWCreditCardFooterLine4": "support_ko{'@'}tripla.io",
    "paymentKRWCreditCardFooterLine4Link": "mailto:support_ko{'@'}tripla.io",
    "paypalCheckbox1": "必ず予約者本人のクレジットカードを使用してください。予約者とカードの名義人が異なる場合、予告無しにキャンセルすることがあります。",
    "paypalCheckbox2": "クレジットカードの発行会社が3Dセキュアに対応していない場合には、利用できません。",
    "paypalCheckbox3": "当社がお客様から収集した以下の個人情報等は、カード発行会社が行う不正利用検知・防止のために、お客様が利用されているカード発行会社へ提供させていただきます。氏名、電話番号、emailアドレス、インターネット利用環境に関する情報等。",
    "paypalCheckbox4": "お客様が利用されているカード発行会社が外国にある場合、これらの情報は当該発行会社が所属する国に移転される場合があります。",
    "paypalCheckbox5": "お客様が未成年の場合、親権者または後見人の承諾を得た上で、本サービスを利用するものとします。",
    "pre_paid": "クレジットカード払い",
    "credit_card": "事前決済",
    "payments": {
      "credit_card": "クレジットカード払い"
    },
    "deferred_payment": "後払い",
    "on_site": "現地決済",
    "on_site_credit_card_required": "現地決済",
    "type": "支払種別",
    "twd_credit_card": "クレジットカードでの全額支払い",
    "twd_credit_card_deposit": "クレジットカードでのデポジット支払い",
    "bank_transfer": "銀行振込",
    "paymentPromotion": "支払いプロモーション",
    "paymentPromotionTooltip": "プロモーションは、予告なしに変更または終了される場合があります。",
    "undefined": ""
  },
  "pointHistory": {
    "availableAt": "利用可能",
    "cancellation": "キャンセル",
    "dateColumn": "日付",
    "descriptionColumn": "内容",
    "failToLoadErrorMessage": "ポイント履歴を読み込み出来ませんでした。",
    "noData": "ポイント履歴はありません。",
    "pointExpirationColumn": "有効期限",
    "pointHistory": "ポイント履歴",
    "pointsColumn": "ポイント数",
    "pointUse": "ポイント利用",
    "stay": "宿泊",
    "viewMore": "追加で読み込む",
    "exchangeDigico": "{point}交換",
    "exchangeDigicoDesc": "デジコポイントに交換したい{point}数を入力してください。デジコポイントは、Amazonギフト券、App Store & iTunesコード、Google Playギフトコードなど、他のポイントプログラムに交換できます。 このプロセスを元に戻すことはできず、デジコポイントは交換後180日間のみ有効です。 このサービスは日本国内でのみご利用いただけます。 交換後のデジコポイントについての質問にはお答えできません。 デジコポイントの詳細については、{link}を参照してください。",
    "exchangeDigicoLink": "こちら",
    "exchangeDigicoLog": "デジコポイント交換",
    "exchangeAvaliablePoints": "利用可能な合計{point}",
    "exchangeBtn": "交換",
    "exchangeHistory": "{point}交換",
    "exchangedAmount": "交換金額",
    "exchangedDigicoCode": "コード",
    "exchangeConfirm": "このプロセスを元に戻すことはできません。 {point}交換を続行してもよろしいですか？",
    "exchangeConfirmBtn": "続行する",
    "digicoPointUrl": "デジコポイント交換URL：",
    "invalidAmount": "有効なポイント額を入力してください。",
    "totalBalance": "合計",
    "currentPoints": "利用可能ポイント",
    "futurePoints": "獲得予定ポイント"
  },
  "pointSpending": {
    "balance": "利用可能ポイント",
    "errors": {
      "incorrectAmount": "ポイントは{n}ポイント毎に利用可能です",
      "moreThanPrice": "宿泊価格以上のポイントは利用できません。",
      "upToBalance": "ポイント残高以上のポイントは指定できません。",
      "pointUsageError": "支払い総額が0円の場合、クレジットカード決済を続行することはできません。\n決済を有効にするには、前のステップで消費ポイントを減らしてください。"
    },
    "minimumAmount": "ポイント利用は{0}ポイント以上からになります。",
    "points": "ポイント",
    "saveForLater": "今回はポイントを利用しない",
    "updateRule": "既存の予約で使用されたポイントは、使用することができません。既存の予約に使用されたポイントは、予約が更新された後に返還されます。",
    "useAll": "持っているポイントを全て使う",
    "useAmount": "利用ポイント数を指定する",
    "title": "ポイント"
  },
  "points": {
    "naming": {
      "default": "ポイント",
      "history": "{point}履歴",
      "available": "利用可能{point}",
      "future": "獲得予定{point}",
      "expired": "期限切れ{point}",
      "gained": "取得{point}",
      "spent": "利用{point}",
      "save": "今回は{point}を利用しない",
      "redeem": "{point}残高を全額引き換える",
      "redeem2": " 最大{point}を使用する",
      "refunded": "払い戻し{point}",
      "miniSpending": "最小使用{point}数は {number}以上である必要があります。",
      "useIncrements": "{point}は {number}単位で使用できます。",
      "discount": "{point} 割引",
      "earn": "この予約で {number} {point}を獲得",
      "checkOut": "{point}はチェックアウト日の 3 日後に追加されます。",
      "usePoint": "{point}分",
      "empty": "現在、{point}履歴はありません。",
      "total": "合計",
      "availableBalance": "利用可能{point}",
      "redeemRemark": "利用{point}数を指定する",
      "cannotUse": "既存の予約で使用された{point}は、使用することができません。既存の予約に使用された{point}は、予約が更新された後に返還されます。"
    },
    "getRegisterPoint": "会員登録して {amount} {points_name} を獲得"
  },
  "progressBar": {
    "completeLottery": "抽選応募完了",
    "completeLotterySubtitle": "抽選応募申込が完了しました",
    "confirmationSubtitle": "ご予約を承りました",
    "confirmationSubtitleOTA": "ご予約を承りました",
    "confirmationTitle": "予約確定",
    "confirmationTitleOTA": "予約完了",
    "guestSubtitle": "お客様情報をご記入ください",
    "guestTitle": "お客様情報",
    "paymentSubtitle": "お支払い方法をお選びください",
    "paymentTitle": "支払い"
  },
  "reservation": {
    "cancelled": "キャンセル済",
    "completed": "宿泊済",
    "upcoming": "予約中",
    "lottery": "抽選",
    "filtersLabel": "絞り込み",
    "filters": {
      "all": "すべて",
      "onSiteChecked": "現地決済",
      "on_site_credit_card_required": "現地決済",
      "otaPrePaidChecked": "{0} によるクレジットカード決済",
      "bookingWidgetPrePaidChecked": "クレジットカードで事前決済",
      "deferredPaymentChecked": "後払い"
    },
    "reservedChannel": {
      "all": "すべて",
      "title": "予約経路",
      "officialWebsite": "公式サイト（現地決済）",
      "wbf": "ダイナミックパッケージ",
      "tripla": "旅行予約サイト（クレジットカード決済）"
    }
  },
  "reservationAction": {
    "contactTriplaSupport": "tripla サポートに問い合わせる",
    "applyGOTO": "クーポンコードを適用する",
    "bookingCancel": "予約をキャンセルする",
    "bookingUpdate": "お客様情報を更新する",
    "corporateSignInRequired": "予約を更新するためには、法人ログインが必要です。",
    "differentFacilityWarning": "予約を更新するためには、予約した施設にてログインしてください。",
    "everythingCorrectSubtitle": "以下より、本予約のお客様情報の更新・キャンセルを行えます。",
    "everythingCorrectTitle": "予約管理",
    "invoiceDownload": "このページを印刷する",
    "madeMistakeHelp": "次回のご利用をお待ちしております。",
    "madeMistakeTitle": "ご予約はキャンセルされました。",
    "memberSignInRequired": "予約を更新するためには、会員ログインが必要です。",
    "pleaseCancelAll": "すべての部屋をキャンセルする場合には、予約をキャンセルしてください",
    "payByCreditCard": "支払方法を事前決済に変更する",
    "reductionOfNights": "減泊",
    "reductionOfRooms": "減室",
    "selectRoomsToCancel": "キャンセルする部屋を選択してください",
    "signOutRequired": "予約を更新するためには、ログアウトが必要です。",
    "updateExpired": "本予約は変更期限を過ぎたため予約変更が出来ません。",
    "updateItinerary": "予約変更",
    "differentAccountWarning": "正しいアカウントでサインインして、予約を更新してください",
    "differentOrgWarning": "正しい法人組織でサインインして、予約を更新してください",
    "coupon": {
      "signOutRequired": "サインアウトしてクーポンを適用してください。",
      "memberSignInRequired": "クーポンの適用には会員ログインが必要です。",
      "corporateSignInRequired": "クーポンを適用するには、法人ログインが必要です。",
      "differentFacilityWarning": "クーポンを適用するには、予約した施設からログインしてください。",
      "differentAccountWarning": "クーポンを適用するには、正しいアカウントでサインインしてください。",
      "differentOrgWarning": "クーポンを適用するには、正しい法人組織でサインインしてください。"
    },
    "notEnoughStays": "予約したプランの最低宿泊日数（{0}泊）以上の宿泊期間を選択してください。",
    "viewMyReservations": "自分の予約を見る",
    "madeMistakeHelp2": "またのご利用をお待ちしております",
    "madeMistakeTitle2": "予約がキャンセルされました"
  },
  "reservationCancellation": {
    "roomN": "{0}部屋目",
    "cancellationFees": "キャンセル料",
    "cancellationConfirm2": "予約をキャンセルしてもよろしいですか?",
    "reason2": "キャンセルの理由を教えてください",
    "reasonTripCancelled2": "旅行計画が変わった",
    "reasonRoomCheaper2": "他社のほうが安かった",
    "reasonNbTrvellers2": "人数が変更になった",
    "reasonDifferentHotel2": "他のホテルを選んだ",
    "reasonOther2": "その他（具体的にご記入ください）",
    "cancel": "ご予約のキャンセル",
    "cancellationConfirm": "ご予約のキャンセルを行いますか？",
    "cancellationFee": "キャンセル料",
    "cancellationOnDay": "当日のキャンセルはオンラインではできません。",
    "cancellationPolicy": "キャンセルポリシー",
    "cancellationTitle": "予約をキャンセルする",
    "contactByChatPhone": "お手数ですが、お電話でご連絡ください。",
    "contactByPhone": "お手数ですが、お電話でご連絡ください。",
    "contactPhone": "電話番号 {0}",
    "keep": "予約したままにする",
    "nonRefundable": "払い戻し不可",
    "reason": "キャンセル理由をお選びください。",
    "reasonDifferentHotel": "他のホテルを予約した",
    "reasonNbTrvellers": "人数が変わった",
    "reasonOther": "その他",
    "reasonOtherHelp": "その他をお選びの方は詳細をお知らせください",
    "reasonRoomCheaper": "他に安い部屋が見つかった",
    "reasonTripCancelled": "旅行がキャンセルになった",
    "roomNbCancelFee": "{0}部屋目 キャンセル料",
    "totalCancelFee": "キャンセル料 合計"
  },
  "reservationConfirmation": {
    "bookingConfirmed": "ご予約が確定しました",
    "cancelledSuccessfully": "予約は正常にキャンセルされました",
    "totalStays": "宿泊日数",
    "contactProperty": "連絡先情報",
    "yourRoomDetails": "お部屋詳細",
    "phone": "電話番号",
    "email": "メールアドレス",
    "website": "ウェブサイト",
    "cancellationFreeUntil": "{0}まではキャンセル料がかかりません",
    "cannotChangePlan": "本予約に含まれているプランは変更出来ません。",
    "confirmationEmail": "{0}に確認メールを送信いたしました",
    "extra": {
      "answer": "回答",
      "dateTime": "利用日時",
      "extrasPrice": "オプション",
      "items": "選択数",
      "notes": "ご注意",
      "price": "価格",
      "priceIncludeTax": "税込価格",
      "question": "質問",
      "roomPrice": "合計宿泊金額"
    },
    "extrasSubtotal": "オプションの小計",
    "nonRefundableReminder": "この予約は返金不可です。キャンセルしても返金されません。",
    "originalReservationPlans": "本予約で選択されているプラン",
    "reservationDetail": "ご予約内容",
    "selectNewItinerary": "変更後のチェックイン/アウト日、人数を選択し、変更可能な部屋タイプ・プランを検索ください。",
    "updateBooking": "予約変更",
    "cannotUpdateTooltip": "予約内容・料金はお客様のご希望に基づいて施設スタッフが事前に変更したもののため、予約の更新/キャンセルはできません。"
  },
  "reservationDetail": {
    "bookingDetails": "予約の詳細確認",
    "adult": "{n}名様",
    "accommodationTax": "宿泊税",
    "checkIn": "チェックイン日",
    "checkOut": "チェックアウト日",
    "child": "{n}名様",
    "dayUse": "日帰り",
    "discount": "割引",
    "discounts": "割引",
    "discountedPrice": "ポイント値引き",
    "guest": "{n}名様",
    "guests": "ご利用人数",
    "hotel": "ホテル",
    "maxGuests": "最大定員",
    "night": "{n}泊",
    "nights": "宿泊日数",
    "numberOfGuest": "ご利用部屋数",
    "numberOfNights": "宿泊日数",
    "originalPrice": "お支払い金額",
    "pointDiscount": "ポイント値引き",
    "pointInstruction": "ポイントはチェックアウトの3日後に加算されます。",
    "pointReward": "この予約で{0}ポイントが加算されます",
    "priceBreakDown": "料金詳細",
    "priceDetails": "料金詳細",
    "reservationNumber": "予約番号",
    "roomDetails": "お部屋の詳細",
    "roomNb": "{0}部屋目",
    "roomPlan": "プラン名",
    "roomRate": "客室料金",
    "roomType": "部屋タイプ",
    "roomWithChildren": "大人 {nAdult} 名と子供 {nChild} 名分の {nRoom} 部屋",
    "roomWithoutChildren": "大人 {nAdult} 名分の {nRoom} 部屋",
    "searchNecessary": "この予約番号は予約の照会・キャンセル時、あるいはホテルへの問合せ時に必須となります。",
    "serviceFee": "サービス料",
    "selected": "あなたが選択した",
    "subTotal": "小計",
    "subTotalWithoutTax": "小計（税抜）",
    "tax": "消費税",
    "taxIncluded": "税込",
    "total": "お支払い金額（税込）",
    "totalLengthOfStay": "宿泊日数",
    "totalPriceWithTax": "税込み総額",
    "depositAmount": "デポジット（{0}％）",
    "remainAmount": "残りのお支払い金額",
    "withTax": "税込",
    "depositNecessary": "予約確認を完了するには、オンラインシステムを介して{0}％のデポジットを支払う必要があります。"
  },
  "reservationPayment": {
    "creditCardPayment": "事前支払い",
    "onSitePayment": "チェックイン時に支払い（前払い不要）",
    "paymentMethod": "支払い方法",
    "pendingPage": {
      "launchButton": "3Dセキュア",
      "text01": "ボタンをクリックすると3Dセキュアのパスワード入力画面に移動します。",
      "text02": "15分以内にパスワード入力が完了しない場合には、予約処理が中止されます。",
      "text03": "予約はパスワードを入力するまで確定されないので、空室が無くなる場合がございます。",
      "text04": "クレジットカード決済はtriplaが代行するので、3Dセキュアの店舗名には「tripla」と表示されます。",
      "timeOutError01": "タイムアウトエラーが発生しました。",
      "timeOutError02": "エラーで予約が完了しなかった場合、通常であれば料金は発生いたしません。\nなお、クレジットカードに請求が発生した場合は返金されます。\n返金時期につきましては、ご利用のクレジットカード会社までお問い合わせくださいませ。",
      "authError01": "決済処理に失敗しました。",
      "error01": "予約処理中にエラーが発生しました。",
      "error02": "エラーで予約が完了しなかった場合、通常であれば料金は発生いたしません。\nなお、クレジットカードに請求が発生した場合は返金されます。\n返金時期につきましては、ご利用のクレジットカード会社までお問い合わせくださいませ。",
      "error03": "「戻る」ボタンをクリックし、検索結果画面から予約をやり直してください。"
    },
    "planError": "現在、このプランでは、選択したお支払い方法はご利用いただけません。再度お試しください",
    "selectCreditCards": "クレジットカードを選択してください",
    "sessionErrorMessage": "問題が発生しました。 ゲストの詳細ページに戻って再試行してください。",
    "sessionErrorButtonText": "ゲストの詳細に戻る"
  },
  "reservationReceipt": {
    "customerName": "宛名",
    "deferredWithoutDownload": "後払いのため、領収書は発行されません。",
    "download": "領収書ダウンロード",
    "downloadOnCheckout": "領収書はチェックアウト日以降にダウンロードできます。",
    "onSite": "現地支払いの場合は、領収書はホテルのフロントデスクでの発行となります。"
  },
  "searchBar": {
    "addRoom": "部屋を追加",
    "adult": "大人{n}名様",
    "adults": "大人",
    "age": "年齢",
    "agree": "同意する",
    "bed": "布団あり",
    "checkIn": "チェックイン",
    "checkInAndOut": "チェックイン - チェックアウト",
    "checkOut": "チェックアウト",
    "child": "子供{n}名様",
    "childN": "子供 {n}",
    "children": "子供",
    "childrenAgeAtCheckout": "チェックアウト時の子どもの年齢",
    "childrenAgeInfant": "幼児 {from}-{to}才",
    "childrenAgePrimary": {
      "primary": "小学生",
      "primaryBottom": "小学生（低学年）",
      "primaryTop": "小学生（高学年）"
    },
    "childrenAges": "子供の年齢",
    "childrenAgeSharedBed": "年齢 {from}-{to}",
    "childrenMealBedding": {
      "meal_no": "食事・布団なし",
      "meal_no_bedding": "布団あり",
      "meal_same_as_adult_bedding": "食事・布団あり",
      "meal_yes": "食事あり",
      "meal_yes_bedding": "食事・布団あり"
    },
    "cookieConsent": "当サイトではサイトの利便性の向上を目的にクッキーを使用します。当サイトの閲覧を続けることで、クッキーの取得に同意したものとみなします。詳細は{0}をご覧ください。",
    "privacyPolicy": "プライバシーポリシー",
    "perRoomGuests": "一部屋あたりのご利用人数",
    "dates": "ご宿泊日",
    "dayUse": "日帰りプランを検索",
    "defaultArea": "全ての地域",
    "edit": "修正",
    "filter": "絞り込み",
    "guests": "ご利用人数",
    "hotel": "ホテル",
    "maxChildrenWarning": "子供の定員は大人1名様つき{n}名です",
    "pleaseSelectHotel": "ホテルを選択してください",
    "room": "{n}部屋",
    "rooms": "部屋数",
    "search": "検索",
    "showOnlyAvailable": "予約可能なもののみ表示",
    "sort": "並び替え",
    "sortOption": {
      "price_high_to_low": "価格の高い順",
      "price_low_to_high": "価格の安い順",
      "recommended": "おすすめ順",
      "asc": "価格の安い順",
      "desc": "価格の高い順"
    },
    "to": "から",
    "undated": "日付未定",
    "nights": "宿泊日数",
    "yearOld": "{n} 歳",
    "enableWBFSearch": "ホテル + 航空券のパッケージ検索を有効にする",
    "stayFlightTab": "ホテル+航空券　",
    "stayTab": "ホテル",
    "processInJapTxt": "",
    "processMaxTxt": "ホテル+航空券プランの最大予約部屋数は 5 室までです。",
    "undatedWarning": "部屋毎の人数が異なる場合には、日付未定検索はご利用いただけません",
    "undatedWarning2": "「航空券付き」で検索した場合はご利用いただけません。",
    "flightAndRoomsWarning": "「日付未定」で検索した場合はご利用いただけません。"
  },
  "searchReservation": {
    "enterDetail": "予約番号をお持ちのお客様 予約番号、メールアドレスを入力してください。",
    "enterReservationNumber": "予約番号を入力",
    "manageBooking": "宿泊予約確認・キャンセル",
    "searchFailed": "メールアドレスや予約番号が入力されていません。ご確認のうえ、もう一度やり直してください。",
    "confirmReservation": "予約確認",
    "confirmReservationDesc": "この予約は {ota} によって支払われているため、{time} 秒以内に {ota} にリダイレクトされます。"
  },
  "searchResultByPlan": {
    "hide": "閉じる",
    "planContent": "プラン内容",
    "plans": "プラン",
    "rooms": "部屋",
    "showPlan": "プラン詳細を表示",
    "moreDetail": "続きを読む"
  },
  "snsLogin": {
    "loginWith": "{0}でログイン",
    "enterPassword": "続行するには、パスワードを入力してください。",
    "existingPrompt": "既存のアカウントをお持ちのようです。\n既存のアカウントと{sns}のアカウントを連携しますか？{sns}とメール・パスワードの両方でログインできるようになります。",
    "signUpDescription": "{sns}アカウントからサインアップしていただきありがとうございます。アカウントの登録を完了するには、続けて情報をご入力ください。"
  },
  "voucher": {
    "cancel": "変更をキャンセル",
    "confirm": "確定する",
    "number": "チケット番号",
    "purchseComplete": "購入完了",
    "selectStay": "チェックイン日を選択ください",
    "stillVoucher": "こちらは宿泊チケットです。まだ正式な予約として宿泊日程は指定されていません。",
    "thankPurchase": "チケットのご購入ありがとうございます",
    "update": "変更を確定",
    "updateItinerary": "予約を変更する",
    "updateItineraryDesc": "新しい宿泊日程を指定してください。「変更確定」ボタンをクリックすると、変更が確定します。",
    "usingVoucher": "チケットを利用する",
    "valid": "このチケットは有効です",
    "voucherDesc": "本チケットを利用するためには宿泊日程を指定してください。\n「予約する」ボタンをクリックすると予約が確定します。",
    "voucherDetails": "チケットの詳細",
    "voucherNumber": "チケット番号"
  },
  "triplaPay": {
    "notFoundMsg": "支払い履歴が見つかりません。 取引は完了しません。",
    "isPaid": "この支払いは正常に完了しました",
    "isAlreadyPaid": "支払い済み",
    "isFailed": "お支払いできませんでした。",
    "isCompleted": "この支払いは正常に完了しました",
    "isRefunded": "お支払いは返金されました",
    "refundedAt": "返金日",
    "paidAt": "支払期日",
    "isCancelled": "お支払いはキャンセルされました。",
    "total": "お支払い金額",
    "pleaseReload": "1時間以内に完了しなかったので、「更新」ボタンをクリックして、再読み込みしてください。",
    "reload": "更新",
    "subject": "支払い対象",
    "amountIsChanged": "お支払い金額が変更されたので、「更新」ボタンをクリックして、再読み込みしてください。",
    "terms": "本決済システムはtripla(株)により提供されており、お客様の個人情報はtripla(株)と共有されることを理解の上で、tripla{0}、{1}、および、{2}に同意します。",
    "paypalTerms": "なお、カード決済にはPayPal を利用しており、カードのご利用明細には、PayPalと記載されます。",
    "temsOfUse": "利用規約",
    "privacyPolicy": "個人情報の取扱い",
    "gdprPolicy": "GDPRポリシー",
    "pleaseScan": "QRコードをスキャンしてください"
  },
  "triplaPayIssues": {
    "INVALID_PARAMETER_SYNTAX": "入力内容をご確認ください。",
    "INVALID_EXPIRATION_YEAR": "有効期限をご確認ください。",
    "Invalid_card_number": "カード番号をご確認ください。",
    "Others": "入力内容をご確認ください。"
  },
  "lottery": {
    "registerForLottery": "抽選申込",
    "selectedDatesError": "抽選イベントの最低宿泊日数以上と最大宿泊日数以下の宿泊期間を選択してください（ {0} ~ {1} 泊）。"
  },
  "recommenderWidget": {
    "previewText": "これはプレビューモードで、有効期間は5分間のみです。5分後にもう一度「プレビュー」をクリックしてください。実際のライブバージョンの設定を保存することを忘れないでください。"
  }
}