import { paymentMethod, ThirdPartyPayment } from 'constants/tripla'

const state = {
  guessedCurrency: '',
  detectedCurrency: '',
  selectedCurrency: '',
  temporaryCurrency: ''
}

const getters = {
  isMcpEnabled: (_, __, rootState, rootGetters) => {
    const whitelist = new Set((process.env.ENABLE_MCP_BRAND_IDS || '').split(','))
    const blacklist = new Set((process.env.DISABLE_MCP_BRAND_IDS || '').split(','))
    const brandId = String(rootGetters['setting/getBrandID'])

    const isFeatureFlagEnabled =
      whitelist.has(brandId) || (whitelist.has('all') && !blacklist.has(brandId))
    const isBrandEnabled = !!rootState.setting.init?.mcp_enabled

    return isFeatureFlagEnabled && isBrandEnabled
  },
  selectedCurrency: (state, _, __, rootGetters) => {
    return (
      state.temporaryCurrency ||
      state.selectedCurrency ||
      state.detectedCurrency ||
      state.guessedCurrency ||
      rootGetters['setting/getCurrency']?.code
    )
  },
  detectedCurrency: (state) => {
    return state.detectedCurrency
  },
  isMcpMode: (_, getters, rootState, rootGetters) => {
    const isOtherCurrencySelected =
      rootGetters['setting/getCurrency']?.code !== getters.selectedCurrency
    const isMcpReservation = rootGetters['booking/getReservationDetails']?.mcp_currency
    return (getters.isMcpEnabled || isMcpReservation) && isOtherCurrencySelected
  },
  temporaryCurrency: (state) => {
    return state.temporaryCurrency
  }
}

const mutations = {
  setGuessedCurrency: (state, payload) => {
    state.guessedCurrency = payload
  },
  setDetectedCurrency: (state, payload) => {
    state.detectedCurrency = payload
  },
  setSelectedCurrency: (state, payload) => {
    state.selectedCurrency = payload
  },
  togglePayment: (state, { method, isMcpAllowedCardBrand, originalCurrency }) => {
    const isAllowedMethod = method === paymentMethod.CreditCard && isMcpAllowedCardBrand
    state.temporaryCurrency = isAllowedMethod ? '' : originalCurrency
  },
  setTemporaryCurrency: (state, currency) => {
    state.temporaryCurrency = currency
  }
}

const actions = {
  setGuessedCurrency: ({ commit }, payload) => {
    commit('setGuessedCurrency', payload)
  },
  setDetectedCurrency: ({ commit }, payload) => {
    commit('setDetectedCurrency', payload)
  },
  setSelectedCurrency: ({ commit }, payload) => {
    commit('setSelectedCurrency', payload)
  },
  togglePayment: ({ commit, rootGetters }, { method, isMcpAllowedCardBrand }) => {
    commit('togglePayment', {
      method,
      isMcpAllowedCardBrand,
      originalCurrency: rootGetters['setting/getCurrency']?.code
    })
  },
  setTemporaryCurrency: ({ commit }, currency) => {
    commit('setTemporaryCurrency', currency)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
