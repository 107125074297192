export default {
  "additionalQuestion": {
    "adultFemale": "أنثى",
    "adultMale": "ذكر",
    "adultOther": "آخر",
    "answerQuestions": "هل يمكنك الإجابة على بعض الأسئلة الإضافية؟",
    "checkinTime": "وقت تسجيل الوصول",
    "checkoutTime": "تحقق من الوقت",
    "moreDetailed": "نحن بحاجة إلى معرفة المزيد عن إقامتك",
    "notificationFromFacility": "إخطار من المنشأة",
    "selectTime": "حدد الوقت",
    "view": "منظر",
    "customizeYourStayExp": "تخصيص تجربة إقامتك",
    "customizeYourStayExpDesc": "هل تمانع في الإجابة على بعض الأسئلة الإضافية؟"
  },
  "base": {
    "account": "حساب",
    "all": "الجميع",
    "apply": "يتقدم",
    "attachFile": "أرفق ملف",
    "backTo": "العودة إلى {0}",
    "booking": "الحجوزات",
    "browserWarning": "متصفحك غير مدعوم رسميًا. إذا وجدت صعوبة في عملية الحجز، يرجى ترقية المتصفح الخاص بك إلى الإصدار الأحدث.",
    "cancel": "يلغي",
    "close": "يغلق",
    "confirm": "تأكيد الحجز الخاص بك",
    "continue": "يكمل",
    "collapse": "ينهار",
    "delete": "يمسح",
    "edit": "يحرر",
    "error": "هناك خطأ ما",
    "error02": "حدث خطأ أثناء معالجة. الرجاء معاودة المحاولة في وقت لاحق.",
    "errorPaymentMethod": "لا يمكن متابعة عملية الحجز لغرف متعددة بطرق دفع مختلفة. يرجى اختيار غرفك بنفس طريقة الدفع أو حجز غرفك في طلبات منفصلة.",
    "oldErrorCode": {
      "1403": "تم تسجيل هذا الحساب من قبل المنشأة ويلزم إعادة تعيين كلمة المرور، يرجى العودة إلى صفحة تسجيل الدخول والضغط على \"نسيت كلمة المرور\"."
    },
    "errorCode": {
      "1903": "يتم قبول الحجوزات لمدة {reservable_period_months} ​​أشهر من اليوم.\nيرجى تحديد فترة الحجز.",
      "2304": "لا تتوافق إعدادات الخطة مع حالة حسابك لأسباب غير معروفة. يرجى التكرم بتحديث الصفحة وإعادة الحجز مرة أخرى.",
      "2401": "لديك نقاط {currentPoint} فقط. لا يمكن إنفاق نقاط {spendPoint}.",
      "3001": "لقد حدث خطأ في المهلة. لم تتم معالجة الدفعة.",
      "3002": "لقد فشلت عملية الدفع.",
      "3003": "هذه البطاقة لم تكن متوفرة.",
      "3004": "يرجى تأكيد رقم البطاقة.",
      "3005": "يرجى التأكد من صحة تاريخ انتهاء الصلاحية.",
      "3006": "فشلت المصادقة الآمنة ثلاثية الأبعاد.",
      "3007": "لا يتم قبول بطاقة الائتمان أو أن رقم البطاقة غير صحيح.",
      "3008": "تم إلغاء المصادقة ثلاثية الأبعاد الآمنة.",
      "3009": "تم تعليق المصادقة ثلاثية الأبعاد الآمنة بسبب طلبات متعددة.",
      "3011": "لقد حدث خطأ في المهلة. لم تتم معالجة الدفعة.",
      "3013": "تحديث هذا الحجز غير متاح حاليًا بسبب التحديثات المستمرة أو معالجة الدفع. يرجى المحاولة مرة أخرى خلال 5 دقائق تقريبًا.",
      "3014": "نظرًا لأنه تم تحديث محتوى الحجز الأصلي الخاص بك، يرجى إلغاء المحتوى الجديد وإعادة تأكيده قبل تحديثه.",
      "3016": "تحديث هذا الحجز غير متاح حاليًا بسبب التحديثات المستمرة أو معالجة الدفع. يرجى المحاولة مرة أخرى خلال 5 دقائق تقريبًا.",
      "3202": "رصيد نقاطك غير متوفر.",
      "3203": "حدث خطأ أثناء معالجة. الرجاء معاودة المحاولة في وقت لاحق.",
      "3204": "لقد فشلت عملية الدفع، يرجى استخدام بطاقة أخرى، أو إعادة المحاولة لاحقاً.",
      "3205": "هذه البطاقة لم تكن متوفرة.",
      "3250": "فشلت المصادقة الآمنة ثلاثية الأبعاد.",
      "3251": "حدث خطأ أثناء معالجة. الرجاء معاودة المحاولة في وقت لاحق.",
      "3252": "البطاقة التي تم إدخالها غير متوافقة مع 3D Secure.",
      "3502": "رصيد نقاطك غير متوفر.",
      "3504": "فشل في الإشارة مرة أخرى، يرجى تأكيد تاريخ النقطة.",
      "3750": "هذه التواريخ مخصصة لحدث اليانصيب فقط. يرجى مراجعة موقع الفندق لمزيد من التفاصيل.",
      "3751": "حدث اليانصيب هذا غير متاح في الوقت الحالي، يرجى مراجعة موقع الفندق لمزيد من التفاصيل",
      "3752": "حدث اليانصيب هذا غير متاح في الوقت الحالي، يرجى مراجعة موقع الفندق لمزيد من التفاصيل",
      "3753": "حدث اليانصيب هذا غير متاح في الوقت الحالي، يرجى مراجعة موقع الفندق لمزيد من التفاصيل",
      "3754": "حدث اليانصيب هذا غير متاح في الوقت الحالي، يرجى مراجعة موقع الفندق لمزيد من التفاصيل",
      "3756": "لقد دخلت في هذا اليانصيب بالفعل. يرجى النقر على \"الحجوزات\" والانتقال إلى قسم \"اليانصيب\" في صفحة ملف تعريف العضو الخاص بك لعرض إدخالاتك.",
      "3757": "حدث اليانصيب هذا غير متاح في الوقت الحالي، يرجى مراجعة موقع الفندق لمزيد من التفاصيل",
      "3760": "حدث اليانصيب هذا غير متاح في الوقت الحالي، يرجى مراجعة موقع الفندق لمزيد من التفاصيل",
      "3780": "الرابط الخاص بك منتهي الصلاحية أو غير صالح، يرجى إعادة زيارة موقع الفندق وإعادة الوصول إلى حدث اليانصيب هذا.",
      "3781": "الرابط الخاص بك منتهي الصلاحية أو غير صالح، يرجى إعادة زيارة موقع الفندق وإعادة الوصول إلى حدث اليانصيب هذا.",
      "3782": "الرابط الخاص بك منتهي الصلاحية أو غير صالح، يرجى إعادة زيارة موقع الفندق وإعادة الوصول إلى حدث اليانصيب هذا.",
      "3783": "الرابط الخاص بك منتهي الصلاحية أو غير صالح، يرجى إعادة زيارة موقع الفندق وإعادة الوصول إلى حدث اليانصيب هذا."
    },
    "sessionExpired": "انتهت صلاحية الجلسة. يرجى تحديث الصفحة للمتابعة.",
    "notAvailableYet": "",
    "fileLimit80MB": "الحد الأقصى لحجم ملف التحميل هو 80 ميجابايت لكل ملف صورة",
    "upTo30FilesError": "لا يمكن إرفاق أكثر من 30 ملفًا",
    "upTo30Files": "ما يصل إلى 30 ملفا",
    "textLengthLimit": "يجب أن تكون نصوص الإجابة 255 حرفًا أو أقل.",
    "triplaCoLtd": "شركة تريبلا المحدودة",
    "triplaAi": "tripla.ai",
    "GDPR": "سياسة الناتج المحلي الإجمالي",
    "here": "هنا",
    "imageError": "فشل تحميل الصورة.",
    "join": "اشتراك",
    "languages": {
      "en": "إنجليزي",
      "ja": "日本語",
      "ko": "شكرا",
      "zh_Hans": "简体中文",
      "zh_Hant": "繁體中文"
    },
    "loading": "يبحث...",
    "login": "تسجيل الدخول",
    "logout": "خروج",
    "logoutMessage": "لقد قمت بتسجيل الخروج بالفعل",
    "signInStatusChangeMessage": "تم تغيير حالة تسجيل الدخول وسيتم إعادة توجيهك إلى صفحة البحث.",
    "memberSignIn": "تسجيل دخول العضوية",
    "memberSignUp": "اشتراك",
    "next": "التالي",
    "notSelected": "لم يتم اختياره",
    "noData": "لايوجد بيانات",
    "optional": "خياري",
    "pay": "يدفع",
    "previous": "سابق",
    "previousPage": "الصفحة السابقة",
    "privacyPolicy": "سياسة الخصوصية",
    "readMore": "اقرأ أكثر",
    "readLess": "أقرأ أقل",
    "recommended": "مُستَحسَن",
    "remove": "يزيل",
    "required": "مطلوب",
    "requireMessage": "لا يمكن أن تكون الحقول المطلوبة فارغة.",
    "reservationStatuses": {
      "cancelled": "ألغيت",
      "done": "محجوز"
    },
    "save": "يحفظ",
    "signInUp": "تسجيل الدخول / أعلى",
    "submit": "يُقدِّم",
    "termError": "يرجى الموافقة على الشروط والأحكام",
    "taxMessage": "يبلغ معدل ضريبة الاستهلاك حاليًا 8%، ولكن من المتوقع أن يرتفع إلى 10% في أكتوبر 2019. بالنسبة لحجوزات الإقامة بعد أكتوبر، يرجى الرجوع مباشرة إلى المبلغ الإجمالي في شاشة تأكيد الحجز. ونحن نقدر تعاونكم والتفاهم.",
    "termsOfUse": "شروط الاستخدام",
    "topPage": "أعلى الصفحة",
    "update": "تحديث",
    "success": "نجاح",
    "unsubscribe": "إلغاء الاشتراك",
    "unsubscribeConfirmation": {
      "error": "خطأ. الرجاء معاودة المحاولة في وقت لاحق.",
      "title": "نحن آسفون أن نراك تذهب. ",
      "description": "إذا كنت لا تزال ترغب في إلغاء الاشتراك في رسائل البريد الإلكتروني الترويجية لدينا، يرجى النقر فوق الزر."
    },
    "unsubscribeSuccessDescribe": "لقد قمت بإلغاء الاشتراك. لن نرسل لك رسائل البريد الإلكتروني الترويجية بعد الآن.",
    "warning": {
      "kidsChanged": "هذه المنشأة لا تدعم شروط البحث المحددة لذلك تم تغيير شروط البحث للأطفال."
    },
    "formalName": "{name}"
  },
  "roomLottery": {
    "updateLottery": "قم بتحديث إدخال اليانصيب الخاص بك",
    "restoreLottery": "استعادة دخول اليانصيب الخاص بك",
    "updateDisabledDesc": "تنتهي فترة الدخول في هذا اليانصيب، يرجى انتظار النتائج.",
    "viewUpdateDetails": "عرض/تحديث التفاصيل",
    "restoreUpdateEntry": "استعادة/تحديث الإدخال",
    "updateProfileDesc": "بعد الفوز باليانصيب وإجراء الحجز، سيتم تطبيق المعلومات المقدمة أعلاه ولا يمكن تغييرها.",
    "cancellationEmailDesc": "لقد أرسلنا رسالة الإلغاء الإلكترونية إلى {0}",
    "cancelModalTitle": "إلغاء دخول اليانصيب الخاص بك",
    "cancelModalDesc": "هل تريد إلغاء دخولك في اليانصيب؟ لا يمكن التراجع عن هذا الإجراء.",
    "cancelEntry": "الغاء الدخول",
    "reservationDeadlinePassed": "لقد مر الموعد النهائي للحجز بالفعل",
    "reservationDeadlinePassedDesc": "نعتذر، ولكن الموعد النهائي للحجز قد انقضى بالفعل. لمزيد من التفاصيل، يرجى زيارة الموقع الإلكتروني للفندق أو الاتصال بنا مباشرة",
    "accountNotMatched": "هذا الحساب لا يتطابق مع الحساب المستخدم عند التقدم لليانصيب. يرجى تسجيل الدخول باستخدام الحساب الصحيح.",
    "resultDateNotice": "سيتم إعلان النتائج في {date} ({day}) {time} ~",
    "resultDateNotice02": "الفائز المحدد فقط هو الذي سيتلقى بريدًا إلكترونيًا آخر لمتابعة عملية الحجز الفعلية.",
    "resultDateNotice03": "يمكنك دائمًا التحقق من الحالة ضمن \"الحجوزات\" في قائمة العضوية.",
    "lotteryEntryNumber": "رقم دخول اليانصيب",
    "lotteryEntryConfirmed": "لقد تم تأكيد دخولك في اليانصيب",
    "lotteryEntryCancelled": "لقد تم إلغاء دخولك في اليانصيب",
    "lotteryResultNotSelected": "لم يتم اختيارك لحدث اليانصيب هذا.",
    "notSelectedNotice": "شكرا للانضمام، ونأمل أن نراكم في المرة القادمة.",
    "lotteryResultSelectedNotified": "🎉 لقد تم اختيارك لتكون الفائز !!",
    "lotteryWinnerNotice01": "※ لم يتم تأكيد الحجز بعد. برجاء استكمال إجراءات الحجز.",
    "lotteryWinnerNotice02": "الموعد النهائي للحجز حتى {date} ({day}) {time}",
    "manageYourLottery": "إدارة اليانصيب",
    "reservationIncomplete": {
      "errorMessage": "هناك عملية أخرى. يرجى الموافقة على الإلغاء إذا كنت ترغب في المتابعة.",
      "checkboxMessage": "أوافق على إلغاء هذه العملية.",
      "modalMessage": "لقد اكتملت عملية الحجز بالفعل وسيتم عرض النتائج."
    }
  },
  "bookingDetail": {
    "overlappedReservation": "لقد أكملت {0} الذي يتداخل مع تاريخ تسجيل الوصول والمغادرة الذي تحجزه الآن، يرجى إعادة التأكيد قبل إجراء الحجز.\n* ربما يكون الحجز المتداخل من منشأة أخرى تابعة لهذه العلامة التجارية للفندق.",
    "overlappedReservationLink": "حجز",
    "overlappedReservationGuest": "لقد أكملت حجزًا يتداخل مع تاريخ تسجيل الوصول والمغادرة الذي تحجزه الآن، يرجى إعادة التأكيد قبل إجراء الحجز.\n* ربما يكون الحجز المتداخل من منشأة أخرى تابعة لهذه العلامة التجارية للفندق.\n*إذا قمت بإلغاء الحجز بواسطة جهاز آخر من قبل، فقد يكون هذا الاكتشاف خاطئًا، يرجى التأكيد عبر البريد الإلكتروني الذي تلقيته.",
    "overlappedReservationGuest2": "*إذا قمت بإلغاء الحجز بواسطة جهاز آخر أو قمت بمسح ملفات تعريف الارتباط، فقد يكون هذا الاكتشاف خاطئًا، يرجى التأكيد عبر البريد الإلكتروني الذي تلقيته."
  },
  "bookingResult": {
    "allUser": "الجميع",
    "availableBetween": "متاح بين",
    "bath": "حمام",
    "shower": "دش",
    "bathtub": "حوض الاستحمام",
    "bedCount": "{ن} سرير | {ن} سرير | {ن} سرير",
    "book": "كتاب",
    "bookAirline": "طيران + غرف",
    "flightRooms": "طيران + غرف",
    "bookAirlineWarning": "خيارات {0} متاحة فقط لتواريخ تسجيل الوصول {1}. يرجى تعديل تاريخ تسجيل الوصول الخاص بك للوصول إلى هذا الخيار.",
    "bookAirlineWarningStay": "تتوفر خيارات {0} فقط لفترة إقامة أقصاها {1} يومًا. يرجى تعديل فترة إقامتك للوصول إلى هذا الخيار.",
    "cancellationFreeUntil": "إلغاء مجاني حتى {0} (بالتوقيت المحلي)",
    "cancellationPolicy": "سياسة الإلغاء",
    "discountAmount": "مقدار الخصم",
    "excludeDuring": "استبعاد أثناء",
    "forNights": "لمدة {ن} ليلة | لمدة {ن} ليلة | لمدة {n} ليلة",
    "freeWifi": "خدمة الواي فاي المجانية داخل الغرفة",
    "followingSearchConditions": "هناك غرف متاحة في هذا التاريخ.",
    "guests": "ضيوف",
    "includes": "يشمل",
    "planInformation": "معلومات الخطة",
    "lowestPriceInfo": "قد لا يتم عرض أقل سعر في التقويم اعتمادًا على حالة المخزون وشروط الخصم. يرجى التحقق من السعر الفعلي من التقويم.",
    "maxAdults": "الحد الأقصى لعدد البالغين هو {n}.",
    "meals": "وجبات",
    "memberExclusive": "حصريا للأعضاء",
    "membersOnly": "الأعضاء فقط",
    "nonSmoking": "غير مدخن",
    "noRoomFound": "عذرًا، لم يُرجع بحثك أي غرف متاحة.",
    "tryChanging": "حاول تغيير {0} أو موقعك.",
    "tryItem": "بلح",
    "noRoomSelected": "لم تقم باختيار أي غرفة",
    "noRoomsAvailable": "عذرًا، لم يُرجع بحثك أي غرف متاحة.",
    "noRoomsAvailableDesc": "حاول تغيير التواريخ أو الموقع.",
    "notAvailable": "غير متاح",
    "priceFor": "السعر لاجل",
    "priceForAdult": "{ن} بالغ | {ن} بالغ | {ن} بالغين",
    "priceForChild": "{ن} طفل | {ن} طفل | {ن} أطفال",
    "priceForRoom": "غرفة",
    "paymentCreditCard": "الدفع عبر الإنترنت عن طريق بطاقة الائتمان",
    "paymentMethods": "قسط",
    "paymentMethod": {
      "on_site": "الدفع في الموقع",
      "credit_card": "الدفع عبر الإنترنت عن طريق بطاقة الائتمان",
      "on_site_credit_card_required": "الدفع في الموقع (يتطلب تسجيل بطاقة الائتمان)",
      "deferred_payment": "الدفع المؤجل",
      "credit_card_full": "الدفع الكامل عن طريق بطاقة الائتمان",
      "credit_card_deposit": "الإيداع عن طريق بطاقة الائتمان",
      "bank_transfer": "التحويل المصرفي",
      "credit_card_krw_domestic": "",
      "credit_card_krw_international": ""
    },
    "paymentNewCreditCards": "الدفع عبر الإنترنت عن طريق بطاقة الائتمان الجديدة",
    "paymentRegisteredCreditCards": "الدفع عبر الإنترنت عن طريق بطاقة الائتمان المسجلة",
    "planDetails": "تفاصيل الخطة",
    "plansLimitForUpdating": "لا يمكنك تحديد الخطط باستثناء تلك الموجودة في الحجوزات الأصلية.",
    "price": "سعر",
    "promotionMayChanged": "قد يتم تغيير العرض الترويجي أو إغلاقه دون سابق إنذار.",
    "registerCard": "سجل هذه البطاقة",
    "regularRate": "معدل منتظم",
    "reservationStartDate": "تاريخ بدء الحجز",
    "showRoomDetails": "عرض تفاصيل الغرفة",
    "roomDetails": "تفاصيل الغرفة",
    "roomFeatures": "معلومات الغرفة",
    "roomLeft": "لم يبق مكان | لم يتبق سوى {n} من الغرف! | لم يتبق سوى {n} من الغرف!",
    "roomAvailable": "لم يبق مكان | {ن} غرفة متبقية | {n} من الغرف المتبقية",
    "roomEnough": "متاح",
    "roomRate": "معدل",
    "rooms": "غرف",
    "roomSize": "حجم الغرفة",
    "roomSizeUnit": {
      "tatami_mats": "حصير التاتامي"
    },
    "select": "يختار",
    "selectNewDate": "حدد تاريخًا جديدًا",
    "serviceFeeDesc": "تشمل هذه الأسعار رسوم الخدمة التي ينفذها موقع الحجز.",
    "sleeps": "ضيوف",
    "smoking": "التدخين",
    "stopReservationUpdate": "إيقاف عملية تحديث الحجز",
    "stopLotteryEntryRestore": "إيقاف عملية استعادة اليانصيب",
    "stopLotteryEntryUpdate": "إيقاف عملية تحديث اليانصيب",
    "targetUser": "المستخدم المستهدف",
    "taxExcluded": "لا تشمل الضرائب",
    "taxIncluded": "شامل الضريبة",
    "toilet": "الحمام",
    "totalPrice": "",
    "totalPriceForNights": "السعر الإجمالي لليلة {n} | السعر الإجمالي لليلة {n} | السعر الإجمالي لـ {n} ليلة",
    "viewAll": "عرض الكل",
    "viewClose": "عرض أقل",
    "viewGallery": "عرض جميع الصور",
    "viewLess": "عرض أقل",
    "viewMore": "عرض جميع خطط {n} | عرض جميع خطط {n}.",
    "viewAllRooms": "عرض جميع الغرف {n} | عرض جميع الغرف {n}.",
    "wifi": "واي فاي",
    "wifiOption1": "غير متاح",
    "wifiOption2": "حر",
    "wifiOption3": "تكلفة",
    "searchResults": "نتائج البحث:",
    "nPlans": "{ن} خطة | خطط {ن}.",
    "nRoomTypes": "{ن} نوع الغرفة | {ن} أنواع الغرف"
  },
  "customAuth": {
    "failTitle": "فشل تسجيل الدخول.",
    "failDesc": "فشل تسجيل الدخول لأن معلومات العضوية غير مسجلة."
  },
  "cancellationPolicy": {
    "policyHeader": "سيتم تطبيق رسوم الإلغاء التالية على حجزك:",
    "policyLine": "{0} أيام قبل تسجيل الوصول: {1}% من السعر.",
    "policyLineWithTime": "{0} يوم (أيام) قبل تسجيل الوصول، وبعد {1}: {2}% من السعر.",
    "freePolicyLine": "{0} أيام قبل تسجيل الوصول: مجانًا.",
    "noShowLine": "عدم الحضور: {0}% من السعر.",
    "cancelOnTheDay": "في يوم تسجيل الوصول: {0}% من السعر.",
    "cancelOnTheDayFree": "في يوم تسجيل الوصول قبل {0}: مجانًا.",
    "cancelOnTheDayCharge": "في يوم تسجيل الوصول بعد {0}: {1}% من السعر.",
    "uponLocalTime": "*يتم احتساب جميع سياسات الإلغاء بناءً على التوقيت المحلي.",
    "uponLocalTimeOTA": "يتم احتساب جميع سياسات الإلغاء بناءً على التوقيت المحلي.",
    "ifBookedElsewhere": "إذا قمت بالحجز من خلال مواقع إلكترونية أو وكالات سفر أخرى، فيرجى إلغاء الحجز من المصدر الأصلي الذي استخدمته.",
    "specialRate": "معدل الإلغاء الخاص مثل: {0}",
    "guest": "ضيف"
  },
  "calendar": {
    "fullyBooked": "محجوز بالكامل",
    "roomsLeft": "#الغرف المتبقية",
    "selected": "المحدد",
    "unavailable": "غير متوفره",
    "unavailableNow": "غير متوفر الآن"
  },
  "corporateAdmin": {
    "admin": "مسؤل",
    "checkInDate": "تاريخ الدخول",
    "download": "تحميل",
    "guestName": "اسم الحجز",
    "hotelName": "اسم فندق",
    "maxNumberStay": "الحد الأقصى لعدد الإقامات",
    "maxNumberStays": "الحد الأقصى لعدد الإقامات",
    "nMonths": "{ن} شهر | {ن} شهر | {ن} أشهر",
    "nights": "ليالي",
    "numberOfRooms": "غرف",
    "remaining": "متبقي",
    "reservationData": "بيانات الحجز",
    "reservationDataDesc1": "※ تُظهر تفاصيل الاستخدام إجمالي عدد الليالي المحجوزة باستخدام موقعنا الإلكتروني. ولذلك، يرجى ملاحظة أن الحجوزات الأخرى (الهاتف، وما إلى ذلك) لا تنعكس.",
    "reservationDataDesc2": "※ قد يستغرق الأمر عدة دقائق من وقت إجراء الحجز حتى تظهر المحتويات على هذه الصفحة.",
    "reservationDataDesc3": "※ لا تنعكس التغييرات / الإلغاءات التي تتم خارج موقعنا على هذه الشاشة.",
    "reservationDate": "تاريخ الحجز",
    "reservationNumber": "رقم الحجز",
    "reserved": "محجوز",
    "status": "حالة",
    "title": "إدارة الشركة",
    "totalPrice": "معدل",
    "period": "فترة",
    "year": "سنة"
  },
  "creditCardForm": {
    "enterYourPaymentDetails": "أدخل تفاصيل الدفع الخاصة بك",
    "addNewCard": "بطاقة ائتمان جديدة",
    "cardExpiration": "تاريخ انتهاء الصلاحية",
    "cardExpirationConfirm": "يرجى تأكيد تاريخ انتهاء الصلاحية",
    "cardHolder": "اسم حامل البطاقة",
    "cardHolderConfirm": "يرجى تأكيد اسم حامل البطاقة",
    "cardHolderFormatError": "الرجاء إدخال اسمك بأحرف رومانية بنصف العرض.",
    "cardHolderHelpOTA": "أدخل الاسم الكامل كما يظهر على بطاقتك",
    "cardHolderHelp": "أدخل الاسم الكامل المطبوع في بطاقتك",
    "cardNumber": "رقم البطاقة",
    "cardNumberConfirm": "يرجى تأكيد رقم البطاقة",
    "cardNumberHelp": "أدخل رقم بطاقتك",
    "cardRequired": "يتم استخدام معلومات بطاقتك الائتمانية فقط لضمان حجزك وتحصيل رسوم الإلغاء بناءً على {0}.",
    "cardSecurityCode": "رمز الحماية",
    "cardSecurityCodeConfirm": "الرجاء تأكيد رمز الحماية",
    "cardSecurityCodeHelp": "CVV",
    "ccvExampleOTA": "رقم مكون من 3 أو 4 أرقام موجود على ظهر بطاقات (Visa وMastercard وJCB وDiners Club) أو على الجانب الأمامي لبطاقات (American Express).",
    "ccvExample": "إنه رقم مكون من 3 أو 4 أرقام مطبوع على ظهر البطاقة (Visa أو Mastercard أو JCB) أو على الجهة الأمامية (American Express). يتم استخدامه لعمليات الشراء عبر الإنترنت للتحقق من أنك حامل البطاقة الشرعي ومنع الاحتيال.",
    "creditCard": "بطاقة إئتمان",
    "expiredError": "يرجى تسجيل بطاقة ائتمان صالحة قبل تاريخ المغادرة.",
    "month": "شهر",
    "payInAdvanceOTA": "تتم معالجة الدفع ببطاقة الائتمان بواسطة {0}.",
    "payInAdvance": "ادفع مقدمًا بواسطة بطاقة الائتمان، وتتم معالجته بواسطة {0}.",
    "payInAdvanceVoucher": "يتم الدفع مقدمًا بواسطة بطاقة الائتمان، وتتم معالجته بواسطة {0}.",
    "payByCreditCard": "سيتم تسوية الدفع بواسطة وكيل السفر ({0}) في وقت اكتمال الحجز.",
    "payByCreditCardConsent": "ستتم مشاركة معلوماتك الشخصية مع وكالة السفر tripla Co., Ltd. ومن خلال متابعة هذا الحجز، فإنك توافق على مشاركة معلوماتك مع {0} و{1} و{2}.",
    "tappayNote": "نحن نستخدم نظام المعاملات TapPay 2048-bit SLL. نحن لا نقوم بحفظ أي تفاصيل بطاقة العملاء. يقوم TapPay بتأمين معلومات بطاقتك الائتمانية من خلال الاعتماد على أنها متوافقة مع PCI DSS عند مستوى مزود الخدمة 1.",
    "year": "سنة",
    "cardBrand": "نوع البطاقة",
    "cardBrandMismatch": "رقم البطاقة لا ينتمي إلى نوع البطاقة المحدد",
    "paymentOverMillionError": "لا يمكن استخدام VISA وMastercard للمدفوعات عبر الإنترنت التي تزيد قيمتها عن مليون ين. يرجى استخدام علامة تجارية أخرى للبطاقة أو اختيار طريقة دفع أخرى.",
    "badCards": "نظرًا لتغييرات النظام، لا يمكن استخدام بطاقة الائتمان التالية لإجراء حجوزات جديدة. الرجاء إدخال معلومات البطاقة مرة أخرى.",
    "gmoBadCards": "نظرًا لتغييرات النظام، نقوم مؤقتًا بتعطيل خيار حفظ أو تحديد بطاقات الائتمان السابقة. للدفع ببطاقة الائتمان، يرجى إدخال تفاصيل البطاقة في كل مرة. نعتذر عن أي إزعاج ونقدر تفهمكم.",
    "pontaVisaCheckbox": "عند استخدام بطاقة Route Inn Hotels PontaVISA، يرجى تحديد \"بطاقة Route Inn Hotels PontaVISA\" باعتبارها العلامة التجارية للبطاقة. إذا قمت باختيار \"VISA\"، فلن يتم منح نقاط المكافأة.",
    "creditCardInvalid": "غير صالح",
    "creditCardInvaildHoverText": "نظرًا لتغييرات النظام، لا يمكن استخدام بطاقة الائتمان التالية لإجراء حجوزات جديدة. الرجاء إدخال معلومات البطاقة مرة أخرى.",
    "rrn": "",
    "firstTwoDigitOfPin": ""
  },
  "gmoPaypalOptions": {
    "null": "اختر نوع البطاقة",
    "visa": "تأشيرة",
    "ponta_visa": "VISA (بطاقة Route Inn Hotels PontaVISA)",
    "master": "بطاقة ماستر بطاقة ائتمان",
    "mastercard": "بطاقة ماستر بطاقة ائتمان",
    "amex": "أمريكان اكسبريس",
    "jcb": "جي سي بي",
    "diners": "نادي داينرز"
  },
  "downloadPasswordModal": {
    "label": "الرجاء إدخال كلمة مرور التشفير. سيتم ضغط الحجز باستخدام كلمة المرور"
  },
  "extraItems": {
    "error": {
      "extraNumber": "الرجاء تغيير رقم {name}. الرقم الذي يمكن حجزه هو {min} ~ {max}."
    }
  },
  "facilitySuggestion": {
    "distanceFrom": "المسافة: {n} كم من هذه المنشأة",
    "from": "من",
    "header": "غرف أخرى متاحة بالقرب من هذا الفندق",
    "soldOut": "نفذ. يرجى تغيير متطلباتك والبحث مرة أخرى.",
    "soldOutDesc": "تواريخك تحظى بشعبية. لقد نفدت الغرف في هذا العقار! حاول تعديل التواريخ أو اختر عقارًا آخر.",
    "visitHotelWebsite": "زيارة موقع الفندق"
  },
  "guestDetail": {
    "afterUpdate": "محدث",
    "bookingForSomeone": "أنا أقوم بالحجز لشخص آخر.",
    "brrDiscount": "أفضل خصم على سعر الغرفة",
    "confirmUpdates": "تأكيد التحديثات",
    "confirmPayByCard": "تأكيد الدفع عن طريق بطاقة الائتمان",
    "coupon": "قسيمة",
    "couponApplied": "تم التطبيق",
    "couponAppliedNew": "الكوبونات المطبقة:",
    "creansmaerdCouponApplied": "يتم حاليًا تطبيق اسم القسيمة {name}.",
    "couponCode": "رمز الكوبون",
    "couponDiscount": "خصم القسيمة",
    "couponExpired": "نأسف ولكن رمز القسيمة الخاص بك لم يعد صالحًا.",
    "couponInvalid": "رمز القسيمة الذي أدخلته غير صحيح.",
    "couponLimitReached": "لم يعد من الممكن استخدام هذه القسيمة {code} بسبب الوصول إلى حد الاستخدام.",
    "couponMaxStays": "لا يمكن استخدام خصم هذه القسيمة إلا لأول {n} من الليالي كحد أقصى. سيتم تحصيل أي ليالي بعد أول {n} من الليالي بالسعر العادي.",
    "couponTargetPlans": "الخطط المستهدفة لرمز القسيمة",
    "couponNotUse": "لا تستخدم أي قسيمة",
    "couponValidUntil": "صالح حتى {التاريخ}",
    "couponUsageQuantityLimit": "يمكن تطبيق رمز قسيمة واحد فقط في المرة الواحدة. يرجى إزالة الرمز المضاف بالفعل لتطبيق رمز جديد.",
    "creditCardWarning": "سيتم إلغاء معاملة الدفع الأصلية لبطاقتك الائتمانية وسيتم خصم مبلغ الدفع الإجمالي الجديد من بطاقتك الائتمانية.",
    "availableCoupons": "القسائم المتاحة",
    "viewMoreCoupons": "عرض المزيد من القسائم",
    "removeCoupon": "إزالة",
    "day": "يوم {ن}",
    "email": "البريد الإلكتروني (يُرجى السماح بتلقي رسائل البريد الإلكتروني من {email})",
    "emailAgain": "تأكيد البريد الإلكتروني)",
    "emailAgainConfirm": "\"البريد الإلكتروني\" و\"تأكيد البريد الإلكتروني\" غير متطابقين",
    "emailAgainHelp": "أدخل عنوان بريدك الإلكتروني مرة أخرى",
    "emailConfirm": "يرجى إدخال عنوان البريد الإلكتروني الصحيح",
    "emailHelp": "أدخل عنوان بريدك الالكتروني",
    "extras": "إضافات",
    "extraLeft": "{عدد} {وحدة} متبقية",
    "extraOutOfStock": "إنتهى من المخزن",
    "fullName": "الاسم الكامل",
    "fullNameError": "يرجى تقديم الاسم الكامل باللغة الإنجليزية",
    "goPreviousScreenConfirm": "العودة إلى الشاشة السابقة؟",
    "goPreviousScreenDescription": "يرجى ملاحظة أنه لن يتم حفظ أي معلومات يتم إدخالها في الصفحة الحالية.",
    "goPreviousScreenContinue": "يكمل",
    "guestDetails": "تفاصيل الضيف",
    "guestName": "اسم الضيف",
    "guestNameError": "يرجى تقديم الاسم الكامل باللغة الإنجليزية",
    "guestNameHelp": "أدخل اسمك الكامل",
    "guestNameKanji": "اسم الضيف (كانجي)",
    "guestStayName": "اسم الضيف",
    "mainGuest": "اتصال رئيسي",
    "notes": "ملحوظات",
    "originalPaymentAmount": "مبلغ الدفع الأصلي",
    "promotionDiscount": "تخفيض",
    "phone": "هاتف",
    "phoneConfirm": "الرجاء إدخال رقم الهاتف الصحيح. يُسمح فقط بالأرقام و+.",
    "phoneHelp": "أدخل رقم هاتفك",
    "priceBecomeCheaper": "أصبحت أسعار الخطط التي حددتها أرخص الآن لأن الفندق قام بتحديث العروض الترويجية. يرجى التحقق من السعر المحدث ومتابعة الحجز.",
    "priceBecomeExpensive": "أصبحت أسعار الخطط التي حددتها أكثر تكلفة الآن لأن الفندق قام بتحديث العروض الترويجية. يرجى التحقق من السعر المحدث بعناية ومتابعة الحجز.",
    "priceDiff": "فرق السعر",
    "signupTerms": "يتم توفير نظام الحجز هذا بواسطة tripla. ستتم مشاركة معلوماتك الشخصية مع tripla. من خلال متابعة تسجيل العضوية هذا، فإنني أوافق على {0} و{1} و{2} الخاصة بـ tripla.",
    "terms": "من خلال إكمال هذا الحجز، فإنك تقر بأنه سيتم مشاركة معلوماتك الشخصية مع شركة tripla Co., Ltd، الشركة التي توفر نظام الحجز هذا. أنت توافق على مشاركة معلوماتك مع tripla {0} و{1} و{2}.",
    "totalAmountWithTax": "المبلغ الإجمالي (مع الضريبة)",
    "totalWithoutTax": "الإجمالي (بدون الضريبة)",
    "updateUserInformation": "قم بتحديث ملف عضويتي بالتغييرات المذكورة أعلاه باستثناء البريد الإلكتروني.",
    "cheaperAccommodationTax": "أصبحت ضريبة الإقامة الخاصة بحجزك أرخص الآن منذ أن قمت باستبدال نقاطك. يرجى التحقق من السعر المحدث ومتابعة الحجز",
    "roomGuestName": "الغرفة {0} اسم الضيف"
  },
  "inventoryCalendar": {
    "availableIconDescription": "{icon}: الغرف المتاحة",
    "unavailableIconDescription": "{icon}: لا توجد غرف متاحة",
    "uncertainIconDescription": "{icon}: توفر محدود"
  },
  "lineLogin": {
    "buttonText": "قم بتسجيل الدخول باستخدام لاين"
  },
  "meta": {
    "facilities": "مرافق العلامة التجارية",
    "sign_in": "تسجيل الدخول",
    "organization_sign_in": "تسجيل الدخول للشركات",
    "sign_up": "اشتراك",
    "search_results": "نتائج البحث",
    "guest_detail": "تفاصيل الضيف",
    "payment_method": "قسط",
    "lotteryCompleted": "تأكيد دخول اليانصيب",
    "completed": "تأكيد",
    "reservationIncomplete": "حجز غير مكتمل",
    "my_account": "صفحتي",
    "otaHome": "خيارات فندقية ذكية مع بحث مدعوم بالذكاء الاصطناعي",
    "otaHomeDescription": "اكتشف أكثر من 2000 مكان إقامة منتقاة بعناية مع tripla، والمدعومة ببحث الذكاء الاصطناعي. بدءًا من نزل اليابان التقليدية وحتى المنتجعات الفاخرة، يمكنك العثور على إقامتك المثالية دون عناء.",
    "otaSearchHotels": "نتائج البحث عن الفنادق",
    "otaSearchResults": "نتائج البحث",
    "otaGuestDetail": "تفاصيل الضيف",
    "otaPayment": "قسط",
    "otaConfirmation": "تأكيد",
    "otaBookingConfirmation": "تأكيد الحجز"
  },
  "membership": {
    "promotionRate": "معدل الترويج",
    "regularRate": "معدل منتظم",
    "signUpFor": "معدل العضوية"
  },
  "membershipForm": {
    "address": "عنوان",
    "alreadyHaveAccount": "هل لديك حساب",
    "back": "خلف",
    "buildingName": "اسم المبنى ورقم الغرفة",
    "changePassword": "تغيير كلمة المرور",
    "changePasswordResult": "تم تغيير كلمة السر الخاصة بك",
    "changePasswordResult02": "✓ تم تحديث كلمة المرور الخاصة بك بنجاح.",
    "changePasswordResult03": "انقر فوق تسجيل الدخول لتسجيل الدخول باستخدام كلمة المرور الجديدة",
    "changePasswordSecondary": "الرجاء إدخال كلمة المرور الجديدة",
    "confirmDeletedResult": "تمت إزالة حسابك بنجاح",
    "confirmNewPassword": "تأكيد كلمة المرور الجديدة",
    "confirmPassword": "تأكيد كلمة المرور",
    "confirmRegisterFail": "هناك خطأ ما",
    "confirmRegisterFail02": "يرجى التسجيل مرة أخرى",
    "confirmRegisterResult": "مصادقة عنوان البريد الإلكتروني",
    "confirmRegisterResult02": "✓ تم التسجيل بنجاح",
    "confirmRegisterResult03": "استمتع بأقامتك",
    "createAccount2": "أنشئ حسابك",
    "createAccount3": "إنشاء حساب",
    "dob": "تاريخ الميلاد",
    "email": "بريد إلكتروني",
    "emailError": "برجاء تأكيد بريدك الالكترونى",
    "emailPromotionCheck": "تلقي رسائل البريد الإلكتروني الترويجية من الفندق.",
    "english_error": "يرجى الإدخال باللغة الإنجليزية.",
    "locale_error": {
      "ja": "يرجى الإدخال باللغة الإنجليزية.",
      "zh_Hant": "يرجى الإدخال باللغة الإنجليزية.",
      "ko": "يرجى الإدخال باللغة الإنجليزية."
    },
    "enterDetail": "أدخل التفاصيل الخاصة بك أدناه.",
    "first_name": "الاسم الأول",
    "firstNameLocales": {
      "ja": "الاسم الأول",
      "zh_Hant": "الاسم الأول",
      "ko": "الاسم الأول"
    },
    "first_name_kanji": "",
    "first_name_kanji_placeholder": "",
    "first_name_placeholder": "جون",
    "firstNamePlaceHolderLocales": {
      "ja": "جون",
      "zh_Hant": "جون",
      "ko": "جون"
    },
    "firstName": "الاسم الأول",
    "firstNameError": "يرجى تأكيد اسمك الأول",
    "firstNameKana": "الاسم الأول",
    "forgotPassword": "نسيت كلمة السر",
    "forgotPassword02": "إعادة تعيين كلمة المرور",
    "forgotPasswordError": "لم يطابق أي مستخدم البريد الإلكتروني المقدم.",
    "forgotPasswordResult": "إعادة تعيين كلمة المرور",
    "forgotPasswordResult02": "✓ تم إرسال بريد إلكتروني لإعادة تعيين كلمة المرور.",
    "forgotPasswordResultSecondary": "تم إرسال رسالة بريد إلكتروني لإعادة تعيين كلمة المرور إلى عنوان البريد الإلكتروني المسجل لحسابك، ولكن قد يستغرق ظهورها في صندوق الوارد الخاص بك عدة دقائق. يرجى الانتظار لمدة 10 دقائق على الأقل قبل محاولة إعادة التعيين مرة أخرى.",
    "forgotPasswordSecondary": "الرجاء إدخال عنوان البريد الإلكتروني الخاص بك. سوف نرسل رابط إعادة تعيين إلى حساب البريد الإلكتروني الخاص بك.",
    "gender": "جنس",
    "generalError": "حدث خطأ أثناء معالجة. الرجاء معاودة المحاولة في وقت لاحق.",
    "guest_first_name": "الاسم الأول",
    "guest_first_name_kanji": "",
    "guest_last_name": "اسم العائلة",
    "guest_last_name_kanji": "",
    "header": "إنشاء حساب",
    "hiragana_error": "",
    "kanji_katakana_error": "الرجاء إدخال كانجي، هيراغانا، كاتاكانا أو الإنجليزية",
    "kanji_error": "",
    "last_name": "اسم العائلة",
    "lastNameLocales": {
      "ja": "اسم العائلة",
      "zh_Hant": "اسم العائلة",
      "ko": "اسم العائلة"
    },
    "last_name_kanji": "",
    "last_name_kanji_placeholder": "",
    "last_name_placeholder": "حداد",
    "lastNamePlaceHolderLocales": {
      "ja": "حداد",
      "zh_Hant": "حداد",
      "ko": "حداد"
    },
    "lastName": "اسم العائلة",
    "lastNameError": "يرجى تأكيد اسمك الأخير",
    "lastNameKana": "اسم العائلة",
    "membershipId": "رقم العضوية",
    "membershipProgram": "برنامج العضوية",
    "name": "اسم",
    "nameJaPlaceholder": "الرجاء إدخال كانجي، هيراغانا، كاتاكانا أو الإنجليزية.",
    "nameJaValidation": "الرجاء إدخال كانجي، هيراغانا، كاتاكانا أو الإنجليزية.",
    "nameInvalidCharMessage": "يحتوي الإدخال الذي قدمته على أحرف غير مدعومة من قبل نظامنا. ",
    "nameKanaJaPlaceholder": "الرجاء إدخال هيراغانا.",
    "namePlaceholder": {
      "en": "الرجاء إدخال اللغة الإنجليزية.",
      "ja": "الرجاء إدخال الهيراجانا أو الإنجليزية.",
      "zh_Hant": "الرجاء إدخال كانجي أو الإنجليزية.",
      "zh_Hans": "الرجاء إدخال كانجي أو الإنجليزية.",
      "ko": "الرجاء إدخال الهانغول أو الإنجليزية."
    },
    "nameValidation": {
      "ja": "الرجاء إدخال الهيراجانا أو الإنجليزية.",
      "zh_Hant": "الرجاء إدخال كانجي أو الإنجليزية.",
      "zh_Hans": "الرجاء إدخال كانجي أو الإنجليزية.",
      "ko": "الرجاء إدخال الهانغول أو الإنجليزية."
    },
    "nationality": "جنسية",
    "newPassword": "كلمة المرور الجديدة (8 أحرف على الأقل بأحرف أبجدية وأرقام كبيرة وصغيرة.)",
    "noAccount": "ليس لديك حساب بعد؟",
    "occupation": "إشغال",
    "or": "أو",
    "orContinueBelow": "أو تابع أدناه",
    "password": "كلمة المرور",
    "passwordConfirmationError": "كلمة المرور الخاصة بك وتأكيدها غير متطابقين.",
    "passwordError": "يرجى التأكد من صحة كلمة المرور الخاصة بك",
    "passwordSignUp": "كلمة المرور (8 أحرف على الأقل بأحرف أبجدية وأرقام كبيرة وصغيرة.)",
    "passwordNote": "ما لا يقل عن 8 أحرف تحتوي على حروف أبجدية وأرقام كبيرة وصغيرة.",
    "pleaseSelect": "حدد البرنامج",
    "postCode": "رمز بريدي",
    "postCodeError": "يُسمح فقط بالحروف الهجائية والأرقام.",
    "prefecture": "المحافظة",
    "notApplicable": "غير قابل للتطبيق",
    "privacyPolicy": "سياسة الخصوصية",
    "promotionsCheckbox": "أريد أن أتلقى العروض الخاصة والعروض الترويجية عبر البريد الإلكتروني",
    "resend": "إعادة إرسال",
    "resendConfirmationEmail": "لم تتلق رسالة تأكيد بالبريد الإلكتروني؟",
    "resendConfirmationEmailError": "لم يتم تسجيل هذا البريد الإلكتروني من قبل.",
    "resendConfirmationEmailTitle": "أعد إرسال رسالة البريد الالكتروني الخاصة بالتأكيد",
    "receiptName": "اسم الإيصال",
    "receiptUnicode": "",
    "send": "يرسل",
    "setPassword": "ضبط كلمة السر",
    "setup": "إنشاء حساب",
    "setupResultSecondary01": "إذا كنت ترغب في إعادة محاولة تسجيل تسجيل العضوية. يرجى المحاولة مرة أخرى من {0}",
    "setupResultSecondary02": "لقد أرسلنا بريدًا إلكترونيًا لتأكيد عنوان البريد الإلكتروني الذي قمت بالتسجيل فيه.\nنظرًا لأن هذا تسجيل مؤقت، يرجى الوصول إلى عنوان URL الموجود في البريد الإلكتروني لإكمال تسجيل العضوية.\nيمكنك تسجيل الدخول بعد الانتهاء من التسجيل.",
    "signIn": "تسجيل الدخول",
    "signInHere": "تسجيل الدخول هنا",
    "signInTo": "تسجيل الدخول إلى {0}",
    "signUp": "إنشاء حساب",
    "signUpResult": "أكد عنوان بريدك الألكتروني",
    "signUpResult02": "سجلت بنجاح",
    "signUpResult02Secondary": "شكرًا لاشتراكك في {n}.",
    "signUpResult02Secondary02": "تم إرسال رسالة تأكيد بالبريد الإلكتروني إلى عنوان البريد الإلكتروني الذي قدمته.",
    "signUpResultSecondary": "شكرًا لاشتراكك في {n}.",
    "signUpResultSecondary02": "لاستخدام حسابك، تحتاج إلى تأكيد عنوان البريد الإلكتروني الذي قدمته.",
    "signUpResultSecondary03": "يرجى التحقق من المحتوى والنقر على عنوان URL التالي.",
    "signUpResultSecondary04": "سيتم الانتهاء من تسجيل العضوية بعد تأكيد عنوان بريدك الإلكتروني.",
    "signUpSecondary": "قم بإنشاء حسابك، يستغرق الأمر أقل من دقيقة.",
    "term": "بالاشتراك، فإنك توافق على {0} و{1}.",
    "termError": "يرجى الموافقة على الشروط والأحكام",
    "termOfUse": "شروط الاستخدام",
    "wifi": "خدمة الاتصال اللاسلكي بالإنترنت عبر تقنية واي فاي مجانية داخل الغرفة",
    "yetReceived": "لم تتلق البريد الإلكتروني؟ {0}",
    "resendEmail": "إعادة إرسال البريد الإلكتروني",
    "pleaseResetPassword": "لقد تم تأكيد تسجيل حساب بريدك الإلكتروني، يرجى ملاحظة أنه ليست هناك حاجة لإعادة إرسال رسالة تأكيد عبر البريد الإلكتروني.\nإذا كنت على علم بكلمة المرور الخاصة بك، فيرجى {0} مباشرة.\nوبدلاً من ذلك، إذا نسيت كلمة المرور الخاصة بك، فيرجى استخدام الخيار {1}.",
    "pleaseResetPasswordLink1": "تسجيل الدخول",
    "pleaseResetPasswordLink2": "إعادة تعيين كلمة المرور",
    "receiptNameFormatError": "",
    "receiptUnicodeFormatError": "",
    "receiptNameRequiredError": "",
    "receiptUnicodeRequiredError": ""
  },
  "membersihp": {
    "target": "العضوية المستهدفة"
  },
  "multipleCurrency": {
    "JPY": "الين الياباني",
    "ARS": "البيزو الأرجنتيني",
    "AUD": "دولار استرالي",
    "BRL": "ريال برازيلي",
    "CAD": "الدولار الكندي",
    "CNY": "اليوان الصيني",
    "CZK": "كورونا التشيكية",
    "DKK": "كرونة دنماركية",
    "EUR": "اليورو",
    "HKD": "الدولار هونج كونج",
    "INR": "الروبية الهندية",
    "IDR": "الروبية الاندونيسية",
    "ILS": "الشيكل الإسرائيلي الجديد",
    "MOP": "ماكاو باكاتا",
    "MYR": "رينغيت ماليزي",
    "MXN": "البيزو المكسيكي",
    "TWD": "دولار تايواني جديد",
    "NZD": "الدولار النيوزيلندي",
    "NOK": "كرونة نرويجية",
    "PHP": "البيزو الفلبيني",
    "GBP": "الجنيه الاسترليني",
    "QAR": "الريال القطري",
    "RUB": "الروبل الروسي",
    "SAR": "الريال السعودي",
    "SGD": "دولار سينغافوري",
    "ZAR": "راند جنوب أفريقيا",
    "KRW": "وون كوريا الجنوبية",
    "LKR": "الروبية السريلانكية",
    "SEK": "كرونة سويدية",
    "CHF": "الفرنك السويسري",
    "THB": "البات التايلندي",
    "AED": "درهم الإمارات العربية المتحدة",
    "USD": "دولار الولايات المتحدة",
    "VND": "دونغ الفيتنامية",
    "modalTitle": "اختر العملة الخاصة بك",
    "modalDescription": "الرجاء تحديد العملة. إذا قمت بتحديد عملة غير الين الياباني، فستقتصر طريقة الدفع الخاصة بك على بطاقة الائتمان.",
    "suggestedCurrencies": "العملات شائعة الاستخدام",
    "allCurrencies": "جميع العملات",
    "creditCardWarning": "لقد تغيرت العملة بسبب تغيير طريقة الدفع. يرجى التحقق من المبلغ.",
    "exchangeRateUpdatedError": "تم تحديث أسعار الصرف، لذا تحقق من أحدث الأسعار وقم بالحجز.",
    "currency": "عملة"
  },
  "myAccount": {
    "availablePoints": "متاح {0}",
    "availablePointsDesc": "إجمالي مبلغ {0} الذي تملكه حاليًا ويمكنك إنفاقه.",
    "totalRankingPoints": "لديك {amount} تصنيف {pointName}.",
    "changeLoginEmail": "تغيير البريد الإلكتروني لتسجيل الدخول",
    "changeLoginEmailDescription": "لتحديث بريدك الإلكتروني يرجى تغييره أدناه. سيتم إرسال رسالة التحقق بالبريد الإلكتروني إليك. إذا كان لديك أي مشاكل في تلقي البريد الإلكتروني، يمكنك تغيير عنوان بريدك الإلكتروني والمحاولة مرة أخرى عدة مرات كما تريد.",
    "changeLoginEmailPendingText": "لقد تم تحديث عنوان بريدك الإلكتروني وهو في انتظار التحقق",
    "changePassword": "إعادة تعيين كلمة المرور",
    "confirmLoginEmail": "في انتظار البريد الإلكتروني الجديد",
    "edit": "يحرر",
    "emailLabel": "بريد إلكتروني",
    "loginEmail": "تسجيل الدخول (البريد الإلكتروني",
    "loginInfo": "معلومات تسجيل الدخول",
    "notRegistered": "غير مسجل",
    "pageTitle": "حساب",
    "password": "كلمة المرور",
    "passwordLabel": "كلمة المرور",
    "paymentInfo": "معلومات الدفع",
    "paymentOptions": "خيارات الدفع",
    "paymentOptionsDesc": "اجعل مشترياتك المستقبلية سريعة وسهلة ومريحة من خلال حفظ معلومات بطاقة الدفع الخاصة بك. سيتم الاحتفاظ بجميع معلومات بطاقة الدفع بسرية تامة.",
    "pendingEmailText": "{n} في انتظار التحقق",
    "personalInfo": "معلومات شخصية",
    "promotions": "قسيمة",
    "point": "نقاط",
    "removeAccount": "إزالة الحساب",
    "removeAccountConfirmationText": "لا يمكنك استرداد حسابك بمجرد حذفه. هل أنت متأكد؟",
    "removeAccountReason01": "لا تستخدم بعد الآن",
    "removeAccountReason02": "رسوم الغرفة باهظة الثمن",
    "removeAccountReason03": "فوائد العضوية ليست جذابة",
    "removeAccountReasonInput": "اكتب السبب الخاص بك",
    "removeAccountReasonOthers": "أخرى (يرجى كتابة الأسباب الخاصة بك)",
    "removeAccountSecondaryText": "هل ترغب في إزالة حسابك؟",
    "removeAccountSecondaryText02": "يرجى ملاحظة أن هذا لا يمكن التراجع عنه",
    "removeCreditCard": "إزالة بطاقة الائتمان",
    "removeCreditCardConfirm": "هل أنت متأكد أنك تريد إزالة بطاقة الائتمان هذه؟",
    "resendEmail": "إعادة التحقق",
    "resendEmailSuccess": "تم إرسال بريد إلكتروني إلى صندوق الوارد الخاص بك.",
    "yourCreditCards": "بطاقات الائتمان الخاصة بك",
    "membershipId": "معرف العضوية",
    "myQRCode": "رمز الاستجابة السريعة الخاص بي",
    "promotion": "إعداد البريد الإلكتروني الترويجي",
    "historyStatus": {
      "expired": "النقاط منتهية الصلاحية",
      "future": "نقاط المستقبل",
      "spent": "النقاط المستهلكة",
      "gained": "النقاط المكتسبة",
      "refunded": "نقاط الاسترداد"
    },
    "value": "قيمة",
    "badCards": "نظرًا لتغييرات النظام، لا يمكن استخدام هذه البطاقة للحجوزات الجديدة.",
    "unlockRank": "احصل على تصنيف {rank} من خلال جمع {points} {pointName} قبل {date}.",
    "unlockRankNoDate": "احصل على تصنيف {rank} عن طريق جمع {points} {pointName}.",
    "earnMorePointsToKeepRank": "اربح {points} أكثر من {pointName} قبل {date} لتحافظ على رتبة {rank}.",
    "achievedHighest": "تهانينا! لقد حققت أعلى رتبة.",
    "achievedHighestOnDate": "تهانينا! لقد استوفيت الشروط وستحصل على أعلى مرتبة في {date}."
  },
  "myProfile": {
    "cancel": "إلغاء الحجز الخاص بك",
    "cancelled": "ألغيت",
    "change": "يتغير",
    "checkIn": "تحقق في",
    "checkOut": "الدفع",
    "confirmationNo": "رقم الحجز",
    "multiRoom": "غرف متعددة",
    "myReservations": "التحفظات",
    "myPage": "صفحتي",
    "noResultButtonText": "ابدأ بالتخطيط لرحلتك",
    "noResultText": "أنت لم تحجز أي شيء بعد.",
    "pageTitle": "الحساب الخاص بك",
    "viewDetails": "عرض التفاصيل",
    "viewFlightDetails": "تفاصيل الحجز (بما في ذلك تذكرة الطائرة)",
    "myVoucher": {
      "amount": "كمية",
      "askConsume": "تستهلك القسيمة؟",
      "askConsumeMessage": "يمكن استخدام هذه القسيمة مرة واحدة فقط.",
      "cancel": "يلغي",
      "consume": "تستهلك",
      "consumeVoucher": "قسيمة الاستهلاك",
      "excludeDuring": "استبعاد خلال",
      "makeReservation": "إجراء الحجز",
      "targetStayingPeriod": "فترة الإقامة المستهدفة",
      "validThrough": "صالحة من خلال",
      "name": "اسم",
      "consumptionTime": "وقت الاستهلاك",
      "reservationNumber": "رقم الحجز",
      "status": {
        "status": "حالة",
        "available": "متاح",
        "pending": "قيد الانتظار",
        "expired": "منتهي الصلاحية",
        "consumed": "مستهلكة"
      },
      "selectHotel": "اختر الفندق"
    },
    "lottery": {
      "status": {
        "registered": "في تَقَدم",
        "not_winner": "لم يتم اختياره",
        "winner_reservation_confirmed": "مختارة(محجوزة)",
        "winner_reservation_cancelled": "تم إلغاء الحجز",
        "registration_cancelled": "تم إلغاء الإدخال",
        "winner_reservation_pending_declined": "تم إلغاء الإدخال",
        "winner_reservation_pending": "تم التحديد (في انتظار التأكيد)"
      },
      "text": "اليانصيب"
    },
    "processBooking": "عملية الحجز",
    "cancelEntry": "الغاء الدخول",
    "lotteryEntryNo": "رقم دخول اليانصيب"
  },
  "organization": {
    "enterDetail": "الرجاء إدخال معرف حسابك وكلمة المرور.",
    "signIn": "تسجيل الدخول للشركات"
  },
  "ota": {
    "changeLoginEmailPendingText": "لقد تم تحديث عنوان بريدك الإلكتروني وهو في انتظار التحقق",
    "name": {
      "agoda": "أجودا",
      "booking_com": "الحجز كوم",
      "expedia": "اكسبيديا",
      "jalan": "جالان",
      "rakuten_travel": "راكوتين ترافيل",
      "rakutenTravel": "راكوتين ترافيل"
    },
    "priceNotAvailable": "غير متاح",
    "loadingTitle0": "اكتشاف إقامتك المثالية",
    "loadingDescription0": "تسخير الذكاء الاصطناعي لاقتراحات الفنادق الشخصية. جارٍ تحميل إقامة أحلامك...",
    "loadingTitle1": "ارفع مستوى تجربتك في اليابان",
    "loadingDescription1": "انغمس في ثقافة اليابان الغنية من خلال الانغماس في حفل الشاي التقليدي. اكتشف فن تحضير الماتشا وتذوق الهدوء.",
    "loadingTitle2": "الجواهر المخفية تنتظر",
    "loadingDescription2": "استكشف عجائب الطرق الوعرة من خلال المغامرة في الأحياء الأقل شهرة. اكتشف الكنوز المحلية واحتضن سحر المدينة الأصيل.",
    "loadingTitle3": "تواصل مع التقليد",
    "loadingDescription3": "انغمس في سحر مناطق الجيشا. شاهد عرضًا للرقص التقليدي أو انخرط في حفل شاي للقاء ثقافي أصيل.",
    "loadingTitle4": "الأطباق الشهية",
    "loadingDescription4": "انغمس في عالم المطبخ الياباني المتنوع من خلال زيارة مختلف أسواق الأطعمة الشهيرة في الشوارع. من السوشي اللذيذ إلى التاكوياكي اللذيذ، قم بإرضاء ذوقك.",
    "loadingTitle5": "الصفاء في الطبيعة",
    "loadingDescription5": "يمكنك الهروب من صخب المناطق الحضرية من خلال القيام بنزهة عبر بساتين الخيزران الساحرة. استمتع بالهدوء والجمال الخالد لهذه المدينة التاريخية.",
    "loadingTitle6": "مشهد زهر الكرز",
    "loadingDescription6": "خطط لزيارتك خلال موسم أزهار الكرز لتشاهد المناظر الطبيعية الشهيرة في اليابان المغطاة بدرجات اللون الوردي. انضم إلى السكان المحليين في احتفالات هانامي واستمتع بتجربة سحرية حقًا.",
    "fromBwToOtaPaymentError": "هناك خطأ ما. يرجى الضغط على زر \"التالي\" أو تحديد طريقة دفع مختلفة للمحاولة مرة أخرى.",
    "fromBwToOtaRestoreDataError": "هناك خطأ ما. يرجى إعادة إدخال بياناتك والضغط على زر \"التالي\" مرة أخرى.",
    "searchHotels": "البحث عن الفنادق",
    "aiRecommendations": "توصيات الذكاء الاصطناعي",
    "recommendationsForU": "توصيات بالنسبة لك",
    "interestedIn": "اكتشف التوصيات البديلة",
    "home": "بيت",
    "showingCountAvailable": "عرض {count} متاح من أصل {total} من الخصائص",
    "standardTravelAgency": "حالة الأعمال",
    "termsOfUse": "شروط الاستخدام",
    "privacyPolicy": "سياسة الخصوصية",
    "companyProfile": "ملف الشركة",
    "bookingSupport": "دعم الحجز",
    "search": "البحث عن الفنادق",
    "checkAvailability": "التحقق من الصلاحية",
    "avgPricePerNight": "السعر المرجعي لليلة الواحدة",
    "adults": "{ن} بالغ | {ن} بالغ | {ن} بالغين",
    "GDPR": "سياسة خصوصية اللائحة العامة لحماية البيانات",
    "homepage": {
      "recommend1": "ابحث عن فنادق مناسبة للميزانية في طوكيو لقضاء عطلة نهاية الأسبوع.",
      "recommend2": "الرجاء مساعدتي في العثور على فندق منتجع في أوكيناوا لثلاثة أشخاص.",
      "recommend3": "استكشف ريوكان في كيوتو بمناسبة عيد ميلاد زوجتي.",
      "recommend4": "وفي الشهر المقبل، سأقوم برحلة عمل إلى كوبي. هل هناك أي فنادق موصى بها للإقامة الفردية؟",
      "placeholder": "على سبيل المثال أرغب في حجز غرفة لشخصين لمدة ثلاث ليالٍ في فندق بالقرب من شينجوكو."
    },
    "bookingConfirmation": "تأكيد الحجز",
    "confirmToOtaTitle": "عملية تأكيد الحجز",
    "confirmToOtaDescription": "ستتم إعادة توجيهك إلى موقع وكالة السفر ({0}). سيتم إكمال حجزك بإكمال الدفعة المقدمة في الصفحة التالية.",
    "confirmToOtaDescriptionInGuestForm": "ستتم إعادة توجيهك إلى موقع وكالة السفر ({0}). سيتم إكمال حجزك بإكمال الدفعة المقدمة في الصفحة التالية.",
    "aiSearchNoResult": "إن الذكاء الاصطناعي الخاص بنا في مرحلة التعلم حاليًا ولم يتمكن من تقديم توصيات ذات صلة بناءً على مدخلاتك. يرجى تقديم المزيد من التفاصيل، مثل الموقع وعدد الأشخاص ووصف المرافق وما إلى ذلك. بالإضافة إلى ذلك، لدينا التوصيات التالية التي قد تجدها مثيرة للاهتمام."
  },
  "otaRoomsResult": {
    "noRoomsAvailable": "للأسف، لا توجد غرف متاحة تلبي متطلباتك.",
    "noRoomsAvailableDesc": "الرجاء اختيار تاريخ آخر، أو تغيير عدد الضيوف لمعرفة ما إذا كان لدينا أي شيء متاح لك.",
    "location": "موقع",
    "showAllPhotos": "عرض جميع الصور"
  },
  "otaRoomsMap": {
    "title": "خريطة",
    "seeMap": "انظر الخريطة"
  },
  "paymentMethod": {
    "paymentKRWCreditCardFooterTitle": "Online payment is processed by:",
    "paymentKRWCreditCardFooterLine1": "tripla Co., Ltd. (Korea branch)",
    "paymentKRWCreditCardFooterLine1CEO": "CEO: Kazuhisa Takahashi",
    "paymentKRWCreditCardFooterLine2": "Business registration number: 538-84-00072",
    "paymentKRWCreditCardFooterLine3": "3F Concordian Building, 76 Saemunan-ro, Jung-gu, Seoul, South Korea (P. 03185)",
    "paymentKRWCreditCardFooterLine4": "support_ko{'@'}tripla.io",
    "paymentKRWCreditCardFooterLine4Link": "mailto:support_ko{'@'}tripla.io",
    "paypalCheckbox1": "يرجى التأكد من استخدام بطاقة الائتمان الخاصة بالشخص الذي قام بالحجز. إذا كان اسم حامل البطاقة لا يتطابق مع اسم الحجز، فقد يتم إلغاء الحجز دون إشعار مسبق.",
    "paypalCheckbox2": "لا يمكن استخدامه إذا كانت جهة إصدار بطاقة الائتمان لا تدعم خدمة 3D Secure.",
    "paypalCheckbox3": "　سيتم تقديم المعلومات الشخصية التالية التي جمعتها شركتنا إلى جهة إصدار البطاقة التي تستخدمها للكشف عن الاستخدام غير المصرح به ومنعه.الاسم ورقم الهاتف وعنوان البريد الإلكتروني وبيئة الإنترنت وما إلى ذلك.",
    "paypalCheckbox4": "إذا كانت جهة إصدار البطاقة التي تستخدمها موجودة في بلد أجنبي، فقد يتم نقل هذه المعلومات إلى الدولة التي ينتمي إليها مصدر البطاقة.",
    "paypalCheckbox5": "يُسمح للقاصرين باستخدام هذه الخدمة بموافقة ولي الأمر أو الوصي.",
    "pre_paid": "الدفع ببطاقة الائتمان",
    "credit_card": "مسبقة الدفع",
    "payments": {
      "credit_card": "الدفع ببطاقة الائتمان"
    },
    "deferred_payment": "الدفع المؤجل",
    "on_site": "بالموقع",
    "on_site_credit_card_required": "بالموقع",
    "type": "نوع الدفع",
    "twd_credit_card": "الدفع الكامل عن طريق بطاقة الائتمان",
    "twd_credit_card_deposit": "الإيداع عن طريق بطاقة الائتمان",
    "bank_transfer": "التحويل المصرفي",
    "paymentPromotion": "ترويج الدفع",
    "paymentPromotionTooltip": "قد يتم تغيير العرض الترويجي أو إغلاقه دون سابق إنذار.",
    "undefined": ""
  },
  "pointHistory": {
    "availableAt": "متواجد في",
    "cancellation": "إلغاء",
    "dateColumn": "تاريخ",
    "descriptionColumn": "وصف",
    "failToLoadErrorMessage": "أنا آسف. لقد فشلنا في تحميل سجل النقاط الخاص بك الآن.",
    "noData": "ليس لديك أي تاريخ نقطة الآن.",
    "pointExpirationColumn": "انتهاء صلاحية النقطة",
    "pointHistory": "تاريخ النقطة",
    "pointsColumn": "مبلغ النقطة",
    "pointUse": "استخدام النقطة",
    "stay": "يقضي",
    "viewMore": "عرض المزيد",
    "exchangeDigico": "{نقطة} صرف",
    "exchangeDigicoDesc": "الرجاء إدخال مبلغ {point} الذي تريد استبداله بنقاط Digico. يمكن استخدام نقاط Digico لاستبدال {نقاطك} ببرامج نقاط أخرى بما في ذلك نقاط Amazon وApple وGoogle. لا يمكن عكس هذه العملية وتكون نقاط Digico صالحة لمدة 180 يومًا فقط بعد الاستبدال. هذه الخدمة متاحة فقط داخل اليابان. لا يمكن للمنشأة الإجابة على أي أسئلة حول نقاط Digico بمجرد استبدالها. لمزيد من المعلومات حول نقاط Digico، يرجى الرجوع إلى {link}.",
    "exchangeDigicoLink": "هنا",
    "exchangeDigicoLog": "تبادل ديجيكو بوينت",
    "exchangeAvaliablePoints": "إجمالي {point} متاح",
    "exchangeBtn": "تبادل",
    "exchangeHistory": "{نقطة} صرف",
    "exchangedAmount": "مبلغ الصرف",
    "exchangedDigicoCode": "شفرة",
    "exchangeConfirm": "لا يمكن عكس هذه العملية. هل أنت متأكد أنك تريد تأكيد تبادل {point} الخاص بك؟",
    "exchangeConfirmBtn": "يكمل",
    "digicoPointUrl": "عنوان URL لتبادل نقاط Digico:",
    "invalidAmount": "الرجاء إدخال مبلغ نقطة صالح.",
    "totalBalance": "المجموع",
    "currentPoints": "النقاط المتاحة",
    "futurePoints": "نقاط المستقبل"
  },
  "pointSpending": {
    "balance": "رصيد النقاط المتوفرة",
    "errors": {
      "incorrectAmount": "يمكنك استخدام النقاط بزيادات {n}.",
      "moreThanPrice": "لا يمكنك استخدام نقاط أكثر من السعر الإجمالي.",
      "upToBalance": "لا يمكنك استخدام نقاط أكثر من رصيدك.",
      "pointUsageError": "لا يُسمح بمتابعة الدفع ببطاقة الائتمان عندما يكون إجمالي دفعتك 0.\nلتفعيل الدفع عبر الإنترنت، يمكنك تقليل إنفاق النقاط في الخطوة السابقة."
    },
    "minimumAmount": "يجب أن يكون الحد الأدنى لمبلغ نقطة الإنفاق {0} نقطة أو أكثر.",
    "points": "نقاط",
    "saveForLater": "احفظ نقاطي لوقت لاحق",
    "updateRule": "لا يمكنك استخدام النقاط المستخدمة للحجز الأصلي. سنقوم برد نقاط الحجز الأصلي بعد تحديث الحجز.",
    "useAll": "استرد رصيد نقاطي بالكامل",
    "useAmount": "استرداد مبلغ محدد",
    "title": "نقاط"
  },
  "points": {
    "naming": {
      "default": "نقطة",
      "history": "{نقطة} التاريخ",
      "available": "متاح {نقطة}",
      "future": "المستقبل {نقطة}",
      "expired": "انتهت الصلاحية {نقطة}",
      "gained": "حصل على {نقطة}",
      "spent": "أنفقت {نقطة}",
      "save": "احفظ {نقطتي} لوقت لاحق",
      "redeem": "استرد رصيد {point} الخاص بي بالكامل",
      "redeem2": "استبدل الحد الأقصى من النقاط",
      "refunded": "استرداد {نقطة}",
      "miniSpending": "الحد الأدنى للإنفاق {point} يجب أن يكون {number} أو أكثر.",
      "useIncrements": "يمكنك استخدام {point} بزيادات قدرها {number}.",
      "discount": "خصم {نقطة}.",
      "earn": "اربح {number} {point} عن طريق حجز هذه الغرفة",
      "checkOut": "ستتم إضافة {point} بعد 3 أيام من تاريخ تسجيل المغادرة.",
      "usePoint": "مع {نقطة}",
      "empty": "ليس لديك أي سجلات {point} الآن.",
      "total": "المجموع",
      "availableBalance": "الرصيد المتاح {point}.",
      "redeemRemark": "استرداد مبلغ محدد",
      "cannotUse": "لا يمكنك استخدام {point} المستخدمة للحجز الأصلي. سنقوم برد {point} مقابل الحجز الأصلي بعد تحديث الحجز."
    },
    "getRegisterPoint": " اكسب {amount} {points_name} عند التسجيل كعضو"
  },
  "progressBar": {
    "completeLottery": "استكمال دخول اليانصيب",
    "completeLotterySubtitle": "لقد أكملت دخول اليانصيب",
    "confirmationSubtitle": "غرفتك (غرفك) محجوزة",
    "confirmationSubtitleOTA": "غرفتك محجوزة",
    "confirmationTitle": "الحجز الكامل",
    "confirmationTitleOTA": "تأكيد",
    "guestSubtitle": "املأ التفاصيل الخاصة بك",
    "guestTitle": "تفاصيل الضيف",
    "paymentSubtitle": "اختر طريقة الدفع",
    "paymentTitle": "قسط"
  },
  "reservation": {
    "cancelled": "ألغيت",
    "completed": "مكتمل",
    "upcoming": "القادمة",
    "lottery": "اليانصيب",
    "filtersLabel": "منقي",
    "filters": {
      "all": "الجميع",
      "onSiteChecked": "الدفع في الموقع",
      "on_site_credit_card_required": "الدفع في الموقع",
      "otaPrePaidChecked": "الدفع ببطاقة الائتمان بواسطة {0}",
      "bookingWidgetPrePaidChecked": "الدفع عبر الإنترنت عن طريق بطاقة الائتمان",
      "deferredPaymentChecked": "الدفع المؤجل"
    },
    "reservedChannel": {
      "all": "الجميع",
      "title": "قناة محجوزة",
      "officialWebsite": "الموقع الرسمي (الدفع في الموقع)",
      "wbf": "الحزمة الديناميكية",
      "tripla": "موقع حجز السفر (الدفع ببطاقة الائتمان)"
    }
  },
  "reservationAction": {
    "contactTriplaSupport": "اتصل بدعم tripla",
    "applyGOTO": "تطبيق رمز القسيمة",
    "bookingCancel": "إلغاء الحجز الخاص بك",
    "bookingUpdate": "تحديث تفاصيل الضيف",
    "corporateSignInRequired": "مطلوب تسجيل الدخول للشركات لتحديث خط سير الرحلة الخاص بك.",
    "differentFacilityWarning": "لتجديد الحجز الخاص بك، يرجى تسجيل الدخول من خلال المنشأة التي قمت بحجزها.",
    "everythingCorrectSubtitle": "يمكنك تحديث معلوماتك أو إلغاء هذا الحجز أدناه.",
    "everythingCorrectTitle": "إدارة الحجز",
    "invoiceDownload": "تفاصيل الطباعة",
    "madeMistakeHelp": "نتطلع لخدمتك مرة أخرى قريبا.",
    "madeMistakeTitle": "لقد تم إلغاء الحجز الخاص بك.",
    "memberSignInRequired": "تسجيل الدخول للعضوية مطلوب لتحديث الحجز.",
    "pleaseCancelAll": "​إذا كنت ترغب في إلغاء جميع الغرف، يرجى إلغاء الحجز.",
    "payByCreditCard": "الدفع بواسطة بطاقة الائتمان",
    "reductionOfNights": "تقليل عدد الليالي",
    "reductionOfRooms": "تخفيض الغرف",
    "selectRoomsToCancel": "يرجى تحديد الغرفة التي ترغب في إلغاءها.",
    "signOutRequired": "الرجاء تسجيل الخروج لتحديث الحجز.",
    "updateExpired": "لم يعد من الممكن تحديث هذا الحجز نظرًا لتجاوز الموعد النهائي.",
    "updateItinerary": "قم بتحديث خط سير الرحلة الخاص بك",
    "differentAccountWarning": "يرجى تسجيل الدخول باستخدام الحساب الصحيح لتحديث الحجز",
    "differentOrgWarning": "يرجى تسجيل الدخول مع الشركة الصحيحة لتحديث الحجز",
    "coupon": {
      "signOutRequired": "الرجاء تسجيل الخروج لتطبيق القسيمة.",
      "memberSignInRequired": "مطلوب تسجيل الدخول للعضوية لتطبيق القسيمة.",
      "corporateSignInRequired": "مطلوب تسجيل الدخول للشركات لتطبيق القسيمة.",
      "differentFacilityWarning": "لتطبيق القسيمة، يرجى تسجيل الدخول من خلال المنشأة التي قمت بحجزها.",
      "differentAccountWarning": "يرجى تسجيل الدخول باستخدام الحساب الصحيح لتطبيق القسيمة.",
      "differentOrgWarning": "يرجى تسجيل الدخول مع الشركة الصحيحة لتطبيق القسيمة."
    },
    "notEnoughStays": "يجب أن تكون مدة الإقامة المحددة مساوية أو أكبر من الحد الأدنى لمدة الإقامة في هذا الحجز ({0} ليلة).\n",
    "viewMyReservations": "عرض حجوزاتي",
    "madeMistakeHelp2": "نحن نتطلع لخدمتك مرة أخرى قريبا",
    "madeMistakeTitle2": "تم إلغاء الحجز"
  },
  "reservationCancellation": {
    "roomN": "الغرفة {0}",
    "cancellationFees": "رسوم إلغاء",
    "cancellationConfirm2": "هل أنت متأكد من إلغاء الحجز الخاص بك؟",
    "reason2": "يرجى مشاركة سبب الإلغاء",
    "reasonTripCancelled2": "التغيير في خطط السفر",
    "reasonRoomCheaper2": "العثور على خيارات أكثر بأسعار معقولة في أماكن أخرى",
    "reasonNbTrvellers2": "تعديل عدد المسافرين",
    "reasonDifferentHotel2": "اختار فندق بديل",
    "reasonOther2": "غير ذلك (يرجى التحديد)",
    "cancel": "إلغاء الحجز",
    "cancellationConfirm": "هل انت متاكد انك تريد الغاء حجزك؟",
    "cancellationFee": "رسوم الإلغاء",
    "cancellationOnDay": "لا يمكن إجراء الإلغاء في نفس اليوم عبر الإنترنت.",
    "cancellationPolicy": "سياسة الإلغاء",
    "cancellationTitle": "إلغاء الحجز الخاص بك",
    "contactByChatPhone": "يرجى الاتصال بنا عن طريق الهاتف.",
    "contactByPhone": "يرجى الاتصال بنا عن طريق الهاتف.",
    "contactPhone": "رقم الهاتف {0}",
    "keep": "الحفاظ على الحجز",
    "nonRefundable": "غير قابل للاسترجاع",
    "reason": "يرجى تزويدنا بسبب الإلغاء",
    "reasonDifferentHotel": "لقد وجدت فندق آخر",
    "reasonNbTrvellers": "لقد تغير عدد المسافرين",
    "reasonOther": "آخر",
    "reasonOtherHelp": "إذا كان الأمر آخر، يرجى تزويدنا بالتفاصيل",
    "reasonRoomCheaper": "الغرفة أرخص في مكان آخر",
    "reasonTripCancelled": "تم إلغاء رحلتي",
    "roomNbCancelFee": "رسوم إلغاء الغرفة {0}.",
    "totalCancelFee": "إجمالي رسوم الإلغاء"
  },
  "reservationConfirmation": {
    "bookingConfirmed": "تم تأكيد حجزك",
    "cancelledSuccessfully": "تم إلغاء الحجز بنجاح",
    "totalStays": "إجمالي مدة الإقامة",
    "contactProperty": "اتصل بالملكية",
    "yourRoomDetails": "تفاصيل غرفتك",
    "phone": "هاتف",
    "email": "بريد إلكتروني",
    "website": "موقع إلكتروني",
    "cancellationFreeUntil": "إلغاء مجاني حتى {0}",
    "cannotChangePlan": "لا يمكنك تغيير خطط الحجز الأصلي.",
    "confirmationEmail": "لقد أرسلنا رسالة التأكيد الإلكترونية إلى {0}",
    "extra": {
      "answer": "إجابة",
      "dateTime": "التاريخ والوقت",
      "extrasPrice": "إضافات",
      "items": "أغراض",
      "notes": "ملحوظات",
      "price": "سعر",
      "priceIncludeTax": "السعر (شامل الضريبة)",
      "question": "سؤال",
      "roomPrice": "سعر الغرفة الإجمالي"
    },
    "extrasSubtotal": "المجموع الفرعي للإضافات",
    "nonRefundableReminder": "هذا الحجز غير قابل للاسترداد. يرجى ملاحظة أنه لن يتم استرداد أي مبلغ عند الإلغاء.",
    "originalReservationPlans": "خطط الحجز الأصلية",
    "reservationDetail": "تفاصيل الحجز",
    "selectNewItinerary": "يرجى تحديد خط سير الرحلة الجديد وعدد الأشخاص.",
    "updateBooking": "قم بتحديث حجزك",
    "cannotUpdateTooltip": "نظرًا لأن محتوى الحجز أو الأسعار قد تم تعديلها من قبل موظفي المنشأة مسبقًا وفقًا لاتفاقيتك، فلن تتمكن من تحديث/إلغاء الحجز."
  },
  "reservationDetail": {
    "bookingDetails": "تفاصيل الحجز",
    "adult": "{ن} بالغ | {ن} بالغ | {ن} الكبار",
    "accommodationTax": "ضريبة السكن",
    "checkIn": "تاريخ الوصول",
    "checkOut": "موعد انتهاء الأقامة",
    "child": "{ن} طفل | {ن} طفل | {ن} أطفال",
    "dayUse": "استخدام اليومي",
    "discount": "تخفيض",
    "discounts": "خصومات",
    "discountedPrice": "خصم النقاط",
    "guest": "{ن} ضيف | {ن} ضيف | {ن} ضيوف",
    "guests": "ضيوف",
    "hotel": "الفندق",
    "maxGuests": "ضيوف ماكس",
    "night": "{ن} ليل | {ن} ليل | {ن} ليالي",
    "nights": "ليالي",
    "numberOfGuest": "غرف",
    "numberOfNights": "ليالي",
    "originalPrice": "إجمالي مبلغ الدفع",
    "pointDiscount": "خصم النقطة",
    "pointInstruction": "سيتم إضافة النقاط بعد 3 أيام من تاريخ تسجيل المغادرة.",
    "pointReward": "اكسب {0} نقطة عن طريق حجز هذه الغرفة",
    "priceBreakDown": "تفاصيل الأسعار",
    "priceDetails": "تفاصيل الأسعار",
    "reservationNumber": "رقم الحجز",
    "roomDetails": "تفاصيل الغرفة",
    "roomNb": "الغرفة {0}",
    "roomPlan": "اسم الخطة",
    "roomRate": "سعر الغرفة",
    "roomType": "نوع الغرفة",
    "roomWithChildren": "{nRoom} غرفة (غرف) لعدد {nAdult} من البالغين و{nChild} طفل (أطفال)",
    "roomWithoutChildren": "{nRoom} غرفة (غرف) لعدد {nAdult} من الأشخاص البالغين",
    "searchNecessary": "* رقم الحجز هذا ضروري عند تحديث/إلغاء الحجز أو الاستفسار عن الحجز لدينا.",
    "serviceFee": "رسوم الخدمة",
    "selected": "لقد حددت",
    "subTotal": "المجموع الفرعي",
    "subTotalWithoutTax": "المجموع الفرعي (بدون ضريبة)",
    "tax": "ضريبة",
    "taxIncluded": "شامل الضريبة",
    "total": "مبلغ الدفع (شامل الضريبة)",
    "totalLengthOfStay": "إجمالي مدة الإقامة",
    "totalPriceWithTax": "السعر الإجمالي مع الضريبة",
    "depositAmount": "مبلغ الإيداع ({0}%)",
    "remainAmount": "الكمية المتبقية",
    "withTax": "مع الضرائب",
    "depositNecessary": "يتعين عليك دفع وديعة بنسبة {0}% عبر نظامنا عبر الإنترنت لإنهاء تأكيد الحجز."
  },
  "reservationPayment": {
    "creditCardPayment": "الدفع المسبق عن طريق بطاقة الائتمان",
    "onSitePayment": "الدفع في الفندق (لا حاجة للدفع المسبق)",
    "paymentMethod": "طريقة الدفع او السداد",
    "pendingPage": {
      "launchButton": "تأمين ثلاثي الأبعاد",
      "text01": "انقر فوق الزر للإشارة إلى شاشة إدخال كلمة المرور ثلاثية الأبعاد الآمنة.",
      "text02": "إذا لم يتم إكمال إدخال كلمة المرور خلال 15 دقيقة، فسيتم إلغاء عملية الحجز.",
      "text03": "لا يكتمل الحجز إلا بعد إدخال كلمة المرور الخاصة بك، لذا قد يكون الشاغر مشغولاً.",
      "text04": "سيتم التعامل مع مدفوعات بطاقات الائتمان بواسطة tripla، لذلك سيتم عرض \"tripla\" في اسم متجر 3D Secure.",
      "timeOutError01": "لقد حدث خطأ في المهلة.",
      "timeOutError02": "لم يتم إكمال الدفع في المسار الطبيعي. ومع ذلك، إذا تم تكبد أي رسوم على بطاقة الائتمان، فسيتم ردها. يرجى تأكيد تفاصيل الجدول الزمني للدفع واسترداد الأموال مع شركة بطاقة الائتمان.",
      "authError01": "لقد فشلت عملية الدفع.",
      "error01": "حدث خطأ أثناء عملية الحجز.",
      "error02": "لم يتم إكمال الدفع في المسار الطبيعي. ومع ذلك، إذا تم تكبد أي رسوم على بطاقة الائتمان، فسيتم ردها. يرجى تأكيد تفاصيل الجدول الزمني للدفع واسترداد الأموال مع شركة بطاقة الائتمان.",
      "error03": "انقر فوق الزر \"رجوع\" واحجز مرة أخرى من شاشة نتائج البحث."
    },
    "planError": "عذرًا، الخطط المحددة بها أنواع دفع متعارضة. حاول مرة اخرى.",
    "selectCreditCards": "حدد بطاقة الائتمان",
    "sessionErrorMessage": "هناك خطأ ما. يرجى العودة إلى صفحة تفاصيل الضيف والمحاولة مرة أخرى.",
    "sessionErrorButtonText": "العودة إلى تفاصيل الضيف"
  },
  "reservationReceipt": {
    "customerName": "اسم العميل للاستلام",
    "deferredWithoutDownload": "لن يتم إصدار الإيصال للمدفوعات المؤجلة.",
    "download": "تحميل الإيصال",
    "downloadOnCheckout": "يمكن تنزيل الإيصال الخاص بك في تاريخ تسجيل المغادرة وفي أي وقت بعده.",
    "onSite": "بالنسبة للمدفوعات في الموقع، سيتم إصدار إيصال في مكتب الاستقبال بالفندق."
  },
  "searchBar": {
    "addRoom": "أضف غرفة أخرى",
    "adult": "{ن} بالغ | {ن} بالغ | {ن} الكبار",
    "adults": "الكبار",
    "age": "عمر",
    "agree": "يوافق",
    "bed": "سرير",
    "checkIn": "تحقق في",
    "checkInAndOut": "تاريخ الدخول - تاريخ الخروج",
    "checkOut": "الدفع",
    "child": "{ن} طفل | {ن} طفل | {ن} أطفال",
    "childN": "طفل {ن}",
    "children": "أطفال",
    "childrenAgeAtCheckout": "أعمار الأطفال عند الخروج",
    "childrenAgeInfant": "العمر {من}-{إلى}",
    "childrenAgePrimary": {
      "primary": "العمر {من}-{إلى}",
      "primaryBottom": "العمر {من}-{إلى}",
      "primaryTop": "العمر {من}-{إلى}"
    },
    "childrenAges": "أعمار الأطفال الذين تسافر معهم؟",
    "childrenAgeSharedBed": "العمر {من}-{إلى}",
    "childrenMealBedding": {
      "meal_no": "لا وجبة والسرير",
      "meal_no_bedding": "السرير فقط",
      "meal_same_as_adult_bedding": "الوجبة والسرير",
      "meal_yes": "وجبة فقط",
      "meal_yes_bedding": "الوجبة والسرير"
    },
    "cookieConsent": "يستخدم هذا الموقع ملفات تعريف الارتباط لتحسين تجربة المستخدم الخاصة بك. من خلال الاستمرار في استخدام هذا الموقع، فإنك قد وافقت على موافقتنا على ملفات تعريف الارتباط. لمزيد من المعلومات، يرجى التحقق من {0}.",
    "privacyPolicy": "سياسة خاصة",
    "perRoomGuests": "عدد الضيوف لكل غرفة",
    "dates": "بلح",
    "dayUse": "كتاب للاستخدام اليومي فقط",
    "defaultArea": "اي موقع",
    "edit": "يحرر",
    "filter": "منقي",
    "guests": "ضيوف",
    "hotel": "الفندق",
    "maxChildrenWarning": "الحد الأقصى لعدد الأطفال لكل شخص بالغ هو {n}",
    "pleaseSelectHotel": "الرجاء اختيار الفندق",
    "room": "{ن} غرفة | {ن} غرفة | {ن} الغرف",
    "rooms": "غرف",
    "search": "يبحث",
    "showOnlyAvailable": "العرض متاح فقط",
    "sort": "نوع",
    "sortOption": {
      "price_high_to_low": "السعر الاعلى الى الادنى",
      "price_low_to_high": "السعر من الارخص للاعلى",
      "recommended": "مُستَحسَن",
      "asc": "السعر من الارخص للاعلى",
      "desc": "السعر الاعلى الى الادنى"
    },
    "to": "ل",
    "undated": "التاريخ غير محدد",
    "nights": "ليالي",
    "yearOld": "{ن} سنة | {ن} سنة | {ن} سنة",
    "enableWBFSearch": "تمكين البحث عن باقة الفندق + رحلة الطيران",
    "stayFlightTab": "الإقامة + الطيران",
    "stayTab": "يقضي",
    "processInJapTxt": "عملية الحجز متاحة حصريًا باللغة اليابانية.",
    "processMaxTxt": "عملية الحجز متاحة حصريًا باللغة اليابانية، بحد أقصى 5 غرف لحجوزات الإقامة + الطيران.",
    "undatedWarning": "البحث دون تحديد تاريخ الدخول غير متاح في حالة اختلاف عدد الأشخاص في كل غرفة.",
    "undatedWarning2": "وهذا غير متاح عند البحث باستخدام \"رحلة الطيران + الغرف\".",
    "flightAndRoomsWarning": "هذا غير متاح عند البحث باستخدام \"التاريخ غير محدد\"."
  },
  "searchReservation": {
    "enterDetail": "أدخل التفاصيل الخاصة بك أدناه.",
    "enterReservationNumber": "أدخل رقم الحجز الخاص بك",
    "manageBooking": "إدارة الحجز",
    "searchFailed": "نحن بحاجة إلى المعلومات التالية للمتابعة. لسوء الحظ، لا يمكننا التعرف على البريد الإلكتروني أو رقم الحجز. حاول مرة اخرى.",
    "confirmReservation": "تأكيد الحجز",
    "confirmReservationDesc": "نظرًا لأن هذا الحجز يتم دفعه بواسطة {ota}، فستتم إعادة توجيهك إلى {ota} خلال {time} ثانية."
  },
  "searchResultByPlan": {
    "hide": "يخفي",
    "planContent": "وصف الخطة",
    "plans": "الخطط",
    "rooms": "غرف",
    "showPlan": "عرض تفاصيل الخطة",
    "moreDetail": "المزيد من التفاصيل"
  },
  "snsLogin": {
    "loginWith": "تسجيل الدخول باستخدام {0}",
    "enterPassword": "أدخل كلمة مرور حساب عضويتك للمتابعة",
    "existingPrompt": "يبدو أن لديك حسابًا موجودًا. هل ترغب في ربط حسابك بـ {sns}؟ ستتمكن من تسجيل الدخول باستخدام {sns} والبريد الإلكتروني/كلمة المرور العادية.",
    "signUpDescription": "شكرًا لك على ربط حسابك {sns}، يرجى تقديم المعلومات المتبقية لإكمال عملية تسجيل الحساب."
  },
  "voucher": {
    "cancel": "يلغي",
    "confirm": "يتأكد",
    "number": "رقم القسيمة",
    "purchseComplete": "اكتملت عملية الشراء",
    "selectStay": "يرجى تحديد خط سير الرحلة الخاص بك",
    "stillVoucher": "هذه لا تزال قسيمة. لم يتم تحديد تسجيل الوصول/المغادرة بعد كحجز مؤكد.",
    "thankPurchase": "شكرا لك على الشراء.",
    "update": "تحديث",
    "updateItinerary": "قم بتحديث خط سير الرحلة الخاص بك",
    "updateItineraryDesc": "يرجى تحديد تاريخ تسجيل الوصول وتاريخ المغادرة المطلوبين لتحديث خط سير الرحلة.",
    "usingVoucher": "احجز الغرفة باستخدام هذه القسيمة",
    "valid": "القسيمة صالحة الآن",
    "voucherDesc": "يمكنك حجز الغرفة باستخدام هذه القسيمة عن طريق تحديد تاريخ تسجيل الوصول/المغادرة هنا.",
    "voucherDetails": "تفاصيل القسيمة",
    "voucherNumber": "رقم القسيمة"
  },
  "triplaPay": {
    "notFoundMsg": "لم يتم العثور على سجل الدفع. لن تكتمل معاملتك.",
    "isPaid": "تمت عملية الدفع هذه بنجاح",
    "isAlreadyPaid": "مدفوع بالفعل",
    "isFailed": "فشل الدفع",
    "isCompleted": "اكتمل الدفع",
    "isRefunded": "تم اعادة دفعتك المالية",
    "refundedAt": "تاريخ استرداد",
    "paidAt": "تاريخ الدفع",
    "isCancelled": "تم إلغاء الدفع",
    "total": "مبلغ الدفع",
    "pleaseReload": "ولم يتم الانتهاء من هذا العمل في ساعة واحدة. أعد التحميل من فضلك.",
    "reload": "إعادة تحميل",
    "subject": "موضوع الدفع",
    "amountIsChanged": "لقد تم تغيير مبلغ الدفعة، يرجى النقر على زر \"تحديث\" للتحديث.",
    "terms": "بالموافقة على tripla {0} و{1} و{2}، فإنك تدرك أن نظام الدفع هذا مقدم من شركة tripla Co., Ltd. وأنه ستتم مشاركة معلوماتك الشخصية مع شركة tripla Co., Ltd.",
    "paypalTerms": "يتم استخدام PayPal للدفع ببطاقات الائتمان، وسيتم ذكر ذلك في كشف حساب بطاقتك.",
    "temsOfUse": "شروط الاستخدام",
    "privacyPolicy": "سياسة الخصوصية",
    "gdprPolicy": "سياسة الناتج المحلي الإجمالي",
    "pleaseScan": "يرجى مسح رمز الاستجابة السريعة"
  },
  "triplaPayIssues": {
    "INVALID_PARAMETER_SYNTAX": "يرجى مراجعة المعلومات التي قمت بإدخالها.",
    "INVALID_EXPIRATION_YEAR": "يرجى التأكد من صحة تاريخ انتهاء الصلاحية.",
    "Invalid_card_number": "يرجى تأكيد رقم البطاقة.",
    "Others": "يرجى مراجعة المعلومات التي قمت بإدخالها."
  },
  "lottery": {
    "registerForLottery": "سجل في اليانصيب",
    "selectedDatesError": "يجب أن تكون مدة الإقامة المحددة ضمن الحد الأقصى والحد الأدنى لمدة الإقامة المسموح بها لحدث اليانصيب هذا. ( {0} ~ {1} ليلة)"
  },
  "recommenderWidget": {
    "previewText": "هذا وضع معاينة، صالح لمدة 5 دقائق فقط. الرجاء النقر فوق \"معاينة\" مرة أخرى بعد 5 دقائق. تذكر حفظ إعداداتك للإصدار المباشر الفعلي."
  }
}